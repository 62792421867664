<template>
  <div id="app">
    <Header v-if="$route.name == 'Auth' || alreadyAuth1" ref="header" />
    <div v-if="$route.name == 'Auth' || alreadyAuth1" class="container-md container-fluid mt-7 py-4">
      <router-view :key="$route.fullPath" />
    </div>
  </div>
</template>
<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import HttpClient from "@/services/httpClient";
import Header from "@/components/Header.vue";
import BasicUtil from './services/basicUtil'
@Component({
  components: {
    Header
  }
})
export default class App extends Vue {
  $refs: any = {
    header: Header
  };
  private dao: HttpClient = new HttpClient();
  private alreadyAuth1: boolean = false

  created() {
    
  }

  mounted() {
    if (this.$route.name != 'Auth') {

    this.alreadyAuth1 = BasicUtil.isAuth(process.env.VUE_APP_GRADE)
    if (!this.alreadyAuth1) this.$router.push("/auth")
    }
    
    const agent = window.navigator.userAgent.toLowerCase();
    if (agent.indexOf("msie") != -1 || agent.indexOf("trident") != -1) {
    } else if (agent.indexOf("edg") != -1 || agent.indexOf("edge") != -1) {
    } else if (agent.indexOf("opr") != -1 || agent.indexOf("opera") != -1) {
    } else if (agent.indexOf("chrome") != -1) {
    } else if (agent.indexOf("safari") != -1) {
      window.onpopstate = function(event: any) {
        window.location.href = "/?error=back";
      };
    } else if (agent.indexOf("firefox") != -1) {
    } else if (agent.indexOf("opr") != -1 || agent.indexOf("opera") != -1) {
    }

  }
}
</script>
<style>
@media (prefers-reduced-motion:no-preference) {
  :root {
    scroll-behavior: auto
  }
}
.relative .question-wrap,
.relative .lecture-wrap
 {
}

.relative .question-wrap p,
.relative .question-wrap li,
.relative .question-wrap table,
.relative .lecture-wrap p
 {
  /* max-width: 900px; */
}
.question-wrap {
  background: white;
  border-radius: 0.7rem;
  padding: 1.5rem;
  -webkit-filter: drop-shadow(1px 1px 3px rgba(0, 0, 0, 0.2));
  -moz-filter: drop-shadow(1px 1px 3px rgba(0, 0, 0, 0.2));
  -ms-filter: drop-shadow(1px 1px 3px rgba(0, 0, 0, 0.2));
  filter: drop-shadow(1px 1px 3px rgba(0, 0, 0, 0.2));
  margin-bottom: 1.5em;
}
.question-wrap h3 {
  font-size: 1.5rem;
  margin-bottom: 1rem;
}
.question-wrap h3 span, .question-number-wrap {
  padding: 0.2em 1em;
  font-size: 1.3rem;
  height: 2rem;
  line-height: 2rem;
  border-radius: 1rem;
  color: white;
  background: #008d7f;
}
.question-number-wrap {
  display: inline-block;
  margin-right: 0.8em;
  line-height: 1.5rem;
  font-size: 1em;
  height: auto;
  padding: auto 1em;
}
.lecture-wrap {
  background: white;
  border-radius: 0.7rem;
  padding: 1.5rem;
  -webkit-filter: drop-shadow(1px 1px 3px rgba(0, 0, 0, 0.2));
  -moz-filter: drop-shadow(1px 1px 3px rgba(0, 0, 0, 0.2));
  -ms-filter: drop-shadow(1px 1px 3px rgba(0, 0, 0, 0.2));
  filter: drop-shadow(1px 1px 3px rgba(0, 0, 0, 0.2));
  margin-bottom: 6em;
}
.lecture-wrap h3 {
  font-size: 1.5rem;
  margin-bottom: 1rem;
}
.lecture-wrap h3 span {
  padding: 0.2em 1em;
  font-size: 1.3rem;
  height: 2rem;
  line-height: 2rem;
  border-radius: 1rem;
  color: white;
  background-color: #008d7f;
}
.per {
  padding: 0.2em 1em;
  font-size: 1.15rem !important;
  height: 2rem;
  line-height: 2rem;
  color: #00584f !important;
  background-color: transparent !important;
  text-decoration: underline;
}
.answerText {
  padding: 0.2em 0.2em !important;
  font-size: 1.6rem !important;
  font-weight: bold;
  height: 2rem;
  line-height: 2rem;
  color: #00584f !important;
  background-color: transparent !important;
}
.lecture-title {
  text-align: center;
  font-size: 1.8em;
  margin: 1em auto;
}
.correct {
  color: #00584f;
}
.incorrect {
  color: brown;
}
.ref-span {
  padding: 0.3em 0.5em;
  background: #FEE7C1;
}
.footer-wrap {
  position: fixed;
  width: 100%;
  left: 0;
  bottom: 0;
}
.footer {
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
}

.justify {
  display: flex;
  justify-content: space-between;
}

.container-fluid {
  margin-right: auto;
  margin-left: auto;
}
body {
  background-repeat: repeat;
  background-image: url(./assets/bg_blu_check.jpg);
  scroll-behavior: auto;
}


.btn-primary {
  font-size: 1.5rem;
  font-weight: bold;
  padding: 0.5rem 1rem;
  border-radius: 0.6rem;
  border-width: 0;
  color: #fff;
  background-color: #01b2a0;
  box-shadow: 0px 4px 0px 0px #008376;
}
    .btn-primary:hover, .btn-primary:focus, .btn-primary:active {
      background-color: #008376;
    }
    .btn-primary:active {
      box-shadow: none;
    }

    .btn-primary:disabled {
      background-color: #004740;
      box-shadow: 0px 4px 0px 0px #00211E;
      opacity: 0.5;
    }
.btn-warning {
  font-size: 1.5rem;
  font-weight: bold;
  padding: 0.5rem 1rem;
  border-radius: 0.6rem;
  color: #010101;
  background-color: #E8E800;
  box-shadow: 0px 4px 0px 0px #c39815;
}
    .btn-warning:hover, .btn-warning:focus, .btn-warning:active {
      background-color: #C2C200;
    }
    .btn-warning:active {
      box-shadow: none;
    }
    .btn-warning:disabled {
      opacity: 0.5;
    }
.btn-secondary {
  font-size: 1.5rem;
  font-weight: bold;
  padding: 0.5rem 1rem;
  border-radius: 0.6rem;
  color: white;
  background-color: #6c757d;
  box-shadow: 0px 4px 0px 0px #444A4F;
}
    .btn-secondary:hover, .btn-secondary:focus, .btn-secondary:active {
      background-color: #444A4F;
    }
    .btn-secondary:active {
      box-shadow: none;
    }
    .btn-secondary:disabled {
      opacity: 0.5;
    }
    .maru {
      line-height: 40px;
    }
    .batu {
      font-size: 2em;
      line-height: 40px;
    }

.btn-light {
  font-size: 1.5rem;
  font-weight: bold;
  padding: 0.5rem 1rem;
  border-radius: 0.6rem;
  color: white;
  background-color: #f08221;
  border-color: #f08221ee;
  box-shadow: 0px 4px 0px 0px #AB5C18;
}
    .btn-light:hover, .btn-light:focus, .btn-light:active {
      background-color: #C46A1B;
      color: white;
      border-color: #f08221ee;
    }
    .btn-light:active {
      box-shadow: none;
    }
    .btn-light:disabled {
      opacity: 0.5;
    }
input.inline-form,
input.inline-formNum
 {
  width: 100%;
  padding: 0.3rem 0.35rem;
  margin: 1px 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.2;
  color: #333;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #999;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 0rem;
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}
select.inline-select {
  border: 1px solid #999;
  margin: 1px 0;
  line-height: 1.2;
  padding: 3px;
  text-align: center;
  font-size: 1rem;
  color: #333;
}
input.inline-formNum {
  text-align: right;
}
input.inline-form::placeholder,
input.inline-formNum::placeholder {
  text-align: center;
  font-weight: 300;
  font-size: 1.2rem;
  color: #000;
}

input.inline-form:disabled,
input.inline-formNum:disabled,
select.inline-select:disabled {
  background-color: #f2f2f2;
}

ol {
  padding-left: 10px;
  margin-left: 25px;
}

ol li {
  list-style-type: none;
  counter-increment: cnt;
  margin-bottom: 1rem;
}
 
ol li::before {
  content: "(" counter(cnt) ") ";
  display: inline-block;
  margin-left: -1.8em;
  width: 30px;
}

input[type=checkbox]:checked:disabled {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

input[type=checkbox]:checked:disabled::after {
  font-size: 18px;
  display: inline-block;
  width: 18px;
  height: 18px;
  content: '✓';
  text-align: center;
}

/* 仕分け中央寄せ */
/* .shiwakeWrap {
  margin: 0 auto !important;;
}

ol li .shiwakeWrap {
  max-width: 835px !important;;
  margin: 0 auto !important;
  padding-right: 35px;
} */

.main-card {
  overflow: hidden;
}

.h6 {
  font-size: 0.88rem !important;
}

.f_box{
    display: flex;
}
.f_item:last-child{
    margin-left: auto;
}
.menu-list-line {
  line-height: 2.75em;
  padding: 0 8px 0 0;
}

.lecture-box {
  background: #E8F6FD;
  padding: 1em;
  border-radius: 8px;
}

.w-button {
  width: 40px;
  padding: 0 !important;
  font-size: 1.4em;
  text-align: center;
}
.choice-wrap {
  padding: auto 1em;
}
.maru.btn-mini {
  min-width: 140px;
  font-size: 1.2em !important;
  line-height: 30px !important;
}
.batu.btn-mini {
  font-size: 1.5em !important;
  min-width: 140px;
  line-height: 30px !important;
}

.table-wrap {
  max-width: 400px;
}
.question-title {
  font-size: 16px;
  font-weight: bold;
}
.question-sub {
  margin-top: 32px;
  margin-bottom: 12px;
  font-size: 16px;
  font-weight: bold;
}
.question-sub tr td:first-child {
  vertical-align: baseline;
}
.kata-question {
  margin-top: 16px;
  margin-bottom: 12px;
  font-size: 16px;
}
.kata-question tr td:first-child {
  vertical-align: baseline;
}
.example {
  margin: 16px 0 8px;
  font-weight: bold;
}
.e-inner-Lmargin {
  margin-left: 8px;
}
.Tmargin16 {
  margin-top: 16px;
}
.Lmargin40 {
  margin-left: 40px;
}
.notice-area {
  margin-top: 16px;
}
.notice-small {
  font-size: 14px;
}

.notice-area p:before{
  content: "※";
  margin-right: 0.3em;
}
.q-img-margin {
  margin: 6px 0 20px;
}
.q-img-margin2 {
  margin: 10px 0;
}
.q-img img {
  width: 100%;
}
@media screen and (max-width: 600px) {
.scroll-table {
  overflow-x: auto;
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: none; /* IE, Edge 対応 */
  scrollbar-width: none; /* Firefox 対応 */
}

.per {
  display: block;
  padding: 0px !important;
  margin: 0px !important;
}
.question-wrap {
  padding: 1em 0.75em;
}
.lecture-wrap {
  padding: 1em 0.75em;
}
/* .list-group-item {
  padding: 0.5em 0;
} */
.choice-wrap {
  padding-left: 0.5em !important;
  padding-right: 0.1em !important;
}
}
.lecture-wrap b {
  color: rgba(28, 133, 199);
}
.lecture-wrap .under-line {
  border-bottom: solid 1px rgba(28, 133, 199);
}

ul li::marker{
  font-weight: 400;

}
.number-style {
  font-family: serif;
}

</style>