<template>
  <div class="home row" >
    <div class="col-xl-6 p-2" v-for="(list, i) in questions" :key="i" >
    <div class="main-card question-wrap p-0 pb-3 ">
      <div class="float-end py-2 px-3 allBtn" v-if="list.length > 0">
        <button @click="allExec(list[0].category)" class="btn btn-light mb-1 h6" type="button">
          全問題学習
        </button>
      </div>
      <h4 class="p-3 m-0" v-if="list.length > 0"><span style="color:#00584f">STAGE {{list[0].category.sort}} {{list[0].category.name}}</span></h4>
      
      <ul class="list-group list-group-flush" style="clear:both;border-top: 1px solid rgba(0,0,0,.125);">
        <li v-for="q in list" :key="q.id" class="list-group-item">
          <div class="f_box">
            <div class="menu-list-line">
              <span class="question-number-wrap" style="font-size:0.88em">LESSON{{q.lessonNo}}</span><b :class="{'h6': q.lesson.name.length > 20}">{{q.lesson.name}}</b>
            </div>
            <div class="f_item text-right">
              <button @click="exec(q.id, q.category, q.lessonNo)" class="btn btn-primary mb-1 mx-1 h6" type="button">
                <i class="fas fa-book-open"></i> 学習
              </button>
              
            </div>
          </div>
        </li>
        <li class="list-group-item" v-if="list.length > 0">
          <div class="f_box">
            <div class="menu-list-line">
              <span class="question-number-wrap" style="font-size:0.88em">実技問題</span>
            </div>
            <div class="f_item text-right">
              <button @click="execJissen(list[0].category)" class="btn btn-primary mb-1 mx-1 h6" type="button">
                <i class="fas fa-book-open"></i> 学習
              </button>
              
            </div>
          </div>
        </li>
      </ul>
    </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Emit } from "vue-property-decorator";
import HttpClient from "@/services/httpClient";
import BasicUtil from "@/services/basicUtil";
import StorageService from "../services/storage";
import Question from "../models/question";
import Category from "../models/category";

@Component({
  components: {}
})
export default class HomeView extends Vue {
  private dao: HttpClient = new HttpClient();
  private StorageService: StorageService = new StorageService()
  private isAllow: boolean = BasicUtil.isAllow();
  private grade: string = process.env.VUE_APP_GRADE;
  private questions: Question[][] = [];

  mounted() {
    new BasicUtil().removeBasicSetting();
    new StorageService().removeCurrentData();
    const categories = this.dao.getCategories();
    for (var i = 0; i < categories.length; i++) {
      const category = categories[i];
      const questions = this.dao.getRontenLessonFromCategory(category);
      this.questions.push(questions);
    }
  }

  private getCount(categoryId: number) {
    return this.StorageService.getIncorrectDataCount(categoryId)
  }

  private hasReexam(categoryId: number) {
    const c =  this.getCount(categoryId)
    return c > 0
  }

  private exec(id: string, category: Category, lessonNo: number) {
    const datas = this.dao.getRontenQuestionsFromCategory(category, lessonNo);
    // const datas = this.dao.getQuestion();
    this.$store.commit("setQuestions", []);
    this.$store.commit("setQuestions", datas);
    new StorageService().setCurrentData(datas);
    new BasicUtil().setBasicSetting();
    this.$router.push("/question/" + id);
  }

  private execJissen(category: Category) {
    const datas = this.dao.getJissenQuestionsFromCategory(category);
    // const datas = this.dao.getQuestion();
    this.$store.commit("setQuestions", []);
    this.$store.commit("setQuestions", datas);
    new StorageService().setCurrentData(datas);
    new BasicUtil().setBasicSetting();
    this.$router.push("/question/" + datas[0].id);
  }

  private random(category: Category) {
    const datas = this.dao.getRandomQuestionsFromCategory(category, 10);
    this.$store.commit("setQuestions", []);
    this.$store.commit("setQuestions", datas);
    new StorageService().setCurrentData(datas);
    new BasicUtil().setBasicSetting();
    this.$router.push("/question/" + datas[0].id);
  }
  private allExec(category: Category) {
    const datas = this.dao.getAllQuestionsFromCategory(category);
    this.$store.commit("setQuestions", []);
    this.$store.commit("setQuestions", datas);
    new StorageService().setCurrentData(datas);
    new BasicUtil().setBasicSetting();
    this.$router.push("/question/" + datas[0].id);
  }
}
</script>
<style scoped>
.top-btn {
  display: inline-block;
  clear: both;
  min-height: 75px;
  text-align: center;
  width: 85%;
  min-width: 200px;
  padding-top: 1.5em !important;
  padding-bottom: 1.5em !important;
  margin: 2em auto;
  font-size: 1.2em;
}

.isSp {
  display: none;
}

.isPc {
  display: block;
}

.subTitle {
  color: #777;
}

@media screen and (max-width: 767px) {
  .isSp {
    display: block;
  }

  .isPc {
    display: none;
  }

  .f_box {
    display: block;
  }
  .f_box button {
    font-size: 0.8em !important;
  }
  .allBtn button {
    font-size: 0.8em !important;
  }
  .f_item {
    text-align: right;
  }

  .question-number-wrap {
    font-size: 0.8em !important;
  }
  .f_box b {
    font-size: 0.84em !important;
  }

  .main-card h4 {
    font-size: 1.2em;
  }
}
</style>