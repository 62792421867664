import Cookies from 'js-cookie';
const passObj: { [name: string]: string } = { "3": "tNDnQKJ6kxUQhVq8HrRxhCEg9XkHvWN8", "2": "MaCyfdh7t4UXFuYmxabEqNLGrvTnr7Rf", "y2": "9ejJva5kecZhRsTcGD2JnEvKMQZwLkBb" }
export default class BasicUtil {
  
  public static getUrlParams() {
    const urls = location.pathname.split("?")
    if (urls.length == 0) return []
    const url = urls[0]
    const arr = url.split("/")
    let first = ""
    let second = ""
    let third = ""
    let forth = ""
    if (arr.length > 1) first = arr[1]
    if (arr.length > 2) second = arr[2]
    if (arr.length > 3) third = arr[3]
    if (arr.length > 4) forth = arr[4]
    return [first, second, third, forth]
  }

  public static getQueryParams(): { [s: string]: string } {
    var queryString = window.location.search;
    var queryObject: { [s: string]: string } = {};
    if (queryString) {
      queryString = queryString.substring(1);
      var parameters = queryString.split('&');

      for (var i = 0; i < parameters.length; i++) {
        var element = parameters[i].split('=');

        var paramName = decodeURIComponent(element[0]);
        var paramValue = decodeURIComponent(element[1]);

        queryObject[paramName] = paramValue;
      }
    }
    return queryObject;
  }

  

  public static isAllow() {
    try {
      localStorage.test = 'app';
      return true
    } catch (e) {
      return false
    }
  }

  public static getSc() {
    var CryptoJS = require('crypto-js');
    var key = CryptoJS.enc.Utf8.parse('XVhujmigeff2LHzaaUXjb5vq5X27dKSp');
    var iv = CryptoJS.enc.Utf8.parse('8337349822330635');
    var dateTime = new Date().toUTCString()
    var srcs = CryptoJS.enc.Utf8.parse(dateTime);
    var encrypted = CryptoJS.AES.encrypt(srcs, key, {
      iv: iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7
    });

    return encrypted.ciphertext.toString();
  }

  public setBasicSetting() {
    
  }

  public removeBasicSetting() {
    window.onbeforeunload = null
  }

  // public static parseMathjax(vm: any, content: string): any {
  //   if (!content || content == "") return ""
  //   const result = content.match(/\$(.+)\$/);
  //   if (result == null || result.length == 0) return content
  //   const propsData: any = {};
  //   propsData["formula"] = result[0];
  //   console.log(vm.classes)
  //   const instance = BasicUtil.getComponent(vm.classes, "VueMathjax", propsData)
  //   if (!instance) return content
  //   if (instance) {
  //     instance.$mount();
  //   }
  //   const uuid = "i" + uuidv4()
  //   content = content.replace(/\$(.+)\$/g, '<span id="' + uuid + '"></span>');
  //   vm.$nextTick(() => {
  //     const div = document.getElementById(uuid);
  //     if (div && instance) div.appendChild(instance.$el);
  //   });
  //   // data-data削除
  //   return content
  // }


  public static onlyNumber(val: string): string {
    if (val.length == 0 || val == "") return "";
    val = val.replace(/,/g, '');
    val = val.replace(/０/g, '0');
    val = val.replace(/１/g, '1');
    val = val.replace(/２/g, '2');
    val = val.replace(/３/g, '3');
    val = val.replace(/４/g, '4');
    val = val.replace(/５/g, '5');
    val = val.replace(/６/g, '6');
    val = val.replace(/７/g, '7');
    val = val.replace(/８/g, '8');
    val = val.replace(/９/g, '9');
    if (/[^\-\.0-9]+/.test(val)) return "";
    return Number(val).toLocaleString()
  }

  public static justifyText(text: string) {
    if (text.indexOf('<span>') !== -1) {
      return text
    }
    const letters: string[] = []
    let isB: boolean = false
    text.split('').forEach((t: string) => {
      if (t == ")") isB = false
      if (isB) {
        letters.push("<span><b>" + t + "</b></span>")
      } else {
        letters.push("<span>" + t + "</span>")
      }
      if (t == "(") isB = true
    })
    return letters.join("")
  }

  public static arrayShuffle(array: any) {
    for (var i = (array.length - 1); 0 < i; i--) {

      var r = Math.floor(Math.random() * (i + 1));

      var tmp = array[i];
      array[i] = array[r];
      array[r] = tmp;
    }
    return array;
  }

  public static setGradePass(grade: string): void {
    Cookies.set('wakaruukaru-app-fp-' + grade, passObj[grade]);
  }

  public static isAuth(grade: string): boolean {
    const cookie = Cookies.get('wakaruukaru-app-fp-' + grade)
    return cookie !== undefined && cookie == passObj[grade]
    // TODO: 必ず削除
    // return true
  }

  public static getQa3() {
    const qas = [
      {
        q: "6ページ「ライフイベント表」の2年経過後の必要資金（3ケタ）を半角で入力してください。",
        a: "170"
      },
    ]
    return qas[Math.floor(Math.random() * qas.length)];
    return { q: "", a: "-" }
  }

  public static getQa2() {
    const qas = [
      {
        q: "１分冊 69ページの(例）で試算したＡさんの老齢基礎年金額６ケタ（カンマ入力不要）を半角で入力してください。",
        a: "756500"
      },
 
    ]
    return qas[Math.floor(Math.random() * qas.length)];
    return { q: "", a: "-" }
  }

}