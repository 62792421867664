
export default [
  {
    id: "1-1",
    diff: 3,
    question: "キャッシュフロー表の作成において、住宅ローンの返済額は、「前年の年間返済額×（１＋物価変動率）」で計算した金額を計上する。",
    lecture: "住宅ローンは予定返済額をそのまま計上します。",
    answer: 0,
    categoryId: 1,
    num: 1,
    type: 1,
    lessonNo: 1
  },
  {
    id: "1-2",
    diff: 3,
    question: "ライフプランニング上の可処分所得は、年間の収入金額から所得税・住民税及び生命保険料を差し引いた金額を使用する。",
    lecture: "可処分所得は、年間の収入金額から所得税・住民税及び社会保険料を差し引いたものです。",
    answer: 0,
    categoryId: 1,
    num: 2,
    type: 1,
    lessonNo: 1
  },
  {
    id: "1-3",
    diff: 3,
    question: "個人の資産や負債の状況を表すバランスシートの作成において、株式などの金融資産や不動産の価額は、取得時点の価額ではなく作成時点の時価で計上する。",
    lecture: null,
    answer: 1,
    categoryId: 1,
    num: 3,
    type: 1,
    lessonNo: 1
  },
  {
    id: "1-4",
    diff: 3,
    question: "クレジットカードで無担保借入（キャッシング）をする行為は、貸金業法 上、総量規制の対象となる。",
    lecture: null,
    answer: 1,
    categoryId: 1,
    num: 4,
    type: 1,
    lessonNo: 1
  },
  {
    id: "1-5",
    diff: 3,
    question: "一定の利率で複利運用しながら一定期間後に目標とする額を得るために必要な毎年の積立額を試算する際、目標とする額に乗じる係数は、減債基金係数である。",
    lecture: null,
    answer: 1,
    categoryId: 1,
    num: 5,
    type: 1,
    lessonNo: 2
  },
  {
    id: "1-6",
    diff: 3,
    question: "税理士の登録を受けていない FP の A さんは、顧客からふるさと納税に関する寄附金控除について相談されたが、所得税法や地方税法の条文等を示し ながら一般的な説明をすることはできない。",
    lecture: "法令の条文等を示しながら一般的な説明をすることは問題ありません。",
    answer: 0,
    categoryId: 1,
    num: 6,
    type: 1,
    lessonNo: 4
  },
  {
    id: "1-7",
    diff: 3,
    question: "金融商品取引業者の登録を受けていない FP のＢさんは、顧客から株式投資についてアドバイスを求められた。FP のＢさんは特定銘柄の株価チャートを解説し、投資のタイミングを助言することはできる。",
    lecture: "金融商品取引業者（投資助言・代理業者・投資運用業者）以外の者は、特定銘柄の投資タイミングの助言を行ってはいけません。",
    answer: 0,
    categoryId: 1,
    num: 7,
    type: 1,
    lessonNo: 4
  },
  {
    id: "1-8",
    diff: 3,
    question: "日本政策金融公庫の教育一般貸付（国の教育ローン）の資金使途は、受験にかかった費用（受験料、受験時の交通費・宿泊費など）と学校納付金（入学金、授業料、施設設備費など）に限定されている。",
    lecture: "入学金や授業料だけでなく、定期代や下宿費用にも使えます。",
    answer: 0,
    categoryId: 1,
    num: 8,
    type: 1,
    lessonNo: 5
  },
  {
    id: "1-9",
    diff: 3,
    question: "日本政策金融公庫の教育一般貸付（国の教育ローン）は、学生の保護者が申込人になることはできず、学生本人が申込人となる。",
    lecture: "申込人は一般的には保護者ですが、一定の条件を満たす場合には学生本人も申込人となることができます。",
    answer: 0,
    categoryId: 1,
    num: 9,
    type: 1,
    lessonNo: 5
  },
  {
    id: "1-10",
    diff: 2,
    question: "個人事業主や農林漁業者などが被保険者となる国民健康保険は、国が保険者として運営している。",
    lecture: "都道府県・市（区）町村か国民健康保険組合が運営しています。",
    answer: 0,
    categoryId: 1,
    num: 10,
    type: 1,
    lessonNo: 8
  },
  {
    id: "1-11",
    diff: 2,
    question: "退職により健康保険の被保険者資格を喪失した者が、健康保険の任意継続被保険者となるためには、資格喪失日の前日までの被保険者期間が継続して 1 年以上なければならない。",
    lecture: "資格喪失日の前日までの被保険者期間が継続して 2 カ月以上なければなりません。",
    answer: 0,
    categoryId: 1,
    num: 11,
    type: 1,
    lessonNo: 8
  },
  {
    id: "1-12",
    diff: 3,
    question: "健康保険の被保険者は、70 歳に達したときにその被保険者資格を喪失し、 後期高齢者医療制度の被保険者となる。",
    lecture: "75 歳に達したときです。",
    answer: 0,
    categoryId: 1,
    num: 12,
    type: 1,
    lessonNo: 8
  },
  {
    id: "1-13",
    diff: 2,
    question: "公的介護保険の第１号被保険者が介護サービスを利用した場合の自己負担割合は、原則として１割だが、一定以上の所得を有する者は２割または３割となる。",
    lecture: null,
    answer: 1,
    categoryId: 1,
    num: 13,
    type: 1,
    lessonNo: 8
  },
  {
    id: "1-14",
    diff: 2,
    question: "労災保険の保険料を計算する際に用いる保険料率は、適用事業所の事業の種類による差異はない。",
    lecture: "労災保険料率は、厚生労働大臣が業種ごとに定めており、事業の種類により異なります。",
    answer: 0,
    categoryId: 1,
    num: 14,
    type: 1,
    lessonNo: 9
  },
  {
    id: "1-15",
    diff: 3,
    question: "雇用保険に係る保険料のうち、失業等給付および育児休業給付に係る保険料は、事業主と労働者が折半して負担する。",
    lecture: null,
    answer: 1,
    categoryId: 1,
    num: 15,
    type: 1,
    lessonNo: 9
  },
  {
    id: "1-16",
    diff: 2,
    question: "雇用保険の一般被保険者が失業した場合、基本手当を受給するためには、原則として、離職の日以前２年間に被保険者期間が通算して 12 カ月以上あること等の要件を満たす必要がある。",
    lecture: null,
    answer: 1,
    categoryId: 1,
    num: 16,
    type: 1,
    lessonNo: 9
  },
  {
    id: "1-17",
    diff: 2,
    question: "特定受給資格者等を除く一般の受給資格者に支給される基本手当の所定給付日数は、被保険者期間が 20 年以上の場合、180 日である。",
    lecture: "一般の受給資格者の所定給付日数は、被保険者期間によって決まります。20 年以上の場合で最長 150 日です。",
    answer: 0,
    categoryId: 1,
    num: 17,
    type: 1,
    lessonNo: 9
  },
  {
    id: "1-18",
    diff: 2,
    question: "高年齢雇用継続基本給付金の支給を受けるためには、一定の一般被保険者に対して支給対象月に支払われた賃金の額が、原則として 60 歳到達時の賃金月額の 85％未満になっていることが必要である。",
    lecture: "75％未満になっていることが必要です。",
    answer: 0,
    categoryId: 1,
    num: 18,
    type: 1,
    lessonNo: 9
  },
  {
    id: "1-19",
    diff: 2,
    question: "一般被保険者や高年齢被保険者が、一定の状態にある家族を介護するために休業する場合、同一の対象家族について、通算 3 回かつ 93 日の介護休業を限度とし、介護休業給付金が支給される。",
    lecture: null,
    answer: 1,
    categoryId: 1,
    num: 19,
    type: 1,
    lessonNo: 9
  },
  {
    id: "1-20",
    diff: 2,
    question: "育児休業給付金は、一般被保険者の休業開始前１年間に、雇用保険の被保険者期間が通算して６カ月以上なければ支給されない。",
    lecture: "休業開始前２年間に、被保険者期間が通算 12 カ月以上あることが必要です。",
    answer: 0,
    categoryId: 1,
    num: 20,
    type: 1,
    lessonNo: 9
  },
  {
    id: "1-21",
    diff: 2,
    question: "育児休業給付金に係る支給単位期間において、一般被保険者や高年齢被保険者に対して支払われた賃金額が、休業開始時賃金日額に支給日数を乗じて得た額の 60％相当額以上である場合、当該支給単位期間について育児休業給付金は支給されない。",
    lecture: "80％相当額以上である場合、当該支給単位期間について育児休業給付金は支給されません。",
    answer: 0,
    categoryId: 1,
    num: 21,
    type: 1,
    lessonNo: 9
  },
  {
    id: "1-22",
    diff: 2,
    question: "国民年金の第 1 号被保険者が出産する場合、所定の届出により、出産予定月の前月から 4 カ月間（多胎妊娠の場合は出産予定月の 3 カ月前から 6 カ月間）、保険料の納付が免除される。",
    lecture: null,
    answer: 1,
    categoryId: 1,
    num: 22,
    type: 1,
    lessonNo: 11
  },
  {
    id: "1-23",
    diff: 2,
    question: "国民年金の保険料免除期間に係る保険料のうち、追納することができる保険料は、追納に係る厚生労働大臣の承認を受けた日の属する月前５年以内の期間に係るものに限られる。",
    lecture: "10 年以内であれば追納できます。",
    answer: 0,
    categoryId: 1,
    num: 23,
    type: 1,
    lessonNo: 11
  },
  {
    id: "1-24",
    diff: 3,
    question: "70 歳以上の者は、厚生年金保険の適用事業所に勤務していても、原則として、厚生年金保険の被保険者とならない。",
    lecture: null,
    answer: 1,
    categoryId: 1,
    num: 24,
    type: 1,
    lessonNo: 14
  },
  {
    id: "1-25",
    diff: 3,
    question: "老齢厚生年金が支給されるためには、老齢基礎年金の受給資格期間を満たし、厚生年金保険の被保険者期間が 1 年以上あることなどの要件を満たす必要がある。",
    lecture: "老齢厚生年金が支給されるためには、老齢基礎年金の受給資格期間を満たし、厚生年金保険の被保険者期間が１カ月以上あることなどの要件を満たす必要があります。",
    answer: 0,
    categoryId: 1,
    num: 25,
    type: 1,
    lessonNo: 14
  },
  {
    id: "1-26",
    diff: 3,
    question: "老齢厚生年金の繰下げ支給を申し出る場合、老齢基礎年金の繰下げ支給と同時に申し出なければならない。",
    lecture: "老齢基礎年金と老齢厚生年金の繰下げは、同時でも別々でもどちらでも可能です。",
    answer: 0,
    categoryId: 1,
    num: 26,
    type: 1,
    lessonNo: 14
  },
  {
    id: "1-27",
    diff: 2,
    question: "国民年金の被保険者でない 20 歳未満の期間に初診日がある傷病により、20歳に達した日またはその日後において障害等級１級または２級に該当する障害の状態にある者は、その者の所得に関わらず、障害基礎年金が支給される。",
    lecture: "20 歳前の障害が原因の人は国民年金保険料を納めていないので所得制限があります。",
    answer: 0,
    categoryId: 1,
    num: 27,
    type: 1,
    lessonNo: 15
  },
  {
    id: "1-28",
    diff: 2,
    question: "障害基礎年金の受給権者が、所定の要件を満たす配偶者を有する場合、その受給権者に支給される障害基礎年金には、配偶者に係る加算額が加算される。",
    lecture: "障害基礎年金に加算されるのは「子の加算額」です。配偶者の加算はありません。",
    answer: 0,
    categoryId: 1,
    num: 28,
    type: 1,
    lessonNo: 15
  },
  {
    id: "1-29",
    diff: 3,
    question: "遺族基礎年金を受給することができる遺族は、国民年金の被保険者などの死亡の当時その者によって生計を維持し、かつ、所定の要件を満たす「子のある配偶者」または「子」である。",
    lecture: null,
    answer: 1,
    categoryId: 1,
    num: 29,
    type: 1,
    lessonNo: 16
  },
  {
    id: "1-30",
    diff: 2,
    question: "国民年金の第１号被保険者としての保険料納付済期間が 36 カ月以上ある者が、老齢基礎年金または障害基礎年金を受けないまま死亡し、その死亡した者によって生計を維持していた遺族が遺族基礎年金の支給を受けられない場合は、原則として、遺族に死亡一時金が支給される。",
    lecture: null,
    answer: 1,
    categoryId: 1,
    num: 30,
    type: 1,
    lessonNo: 16
  },
  {
    id: "1-31",
    diff: 2,
    question: "遺族厚生年金を受給することができる遺族の範囲は、厚生年金保険の被保険者または被保険者であった者の死亡の当時、その者によって生計を維持し、かつ、所定の要件を満たす配偶者、子、父母、孫または祖父母である。",
    lecture: null,
    answer: 1,
    categoryId: 1,
    num: 31,
    type: 1,
    lessonNo: 16
  },
  {
    id: "1-32",
    diff: 3,
    question: "厚生年金保険の被保険者である夫が死亡し、子のない 30 歳未満の妻が遺族厚生年金の受給権を取得した場合、その妻に対する遺族厚生年金の支給期間は、最長で 7 年間である。",
    lecture: "支給期間は、最長で 5 年です。",
    answer: 0,
    categoryId: 1,
    num: 32,
    type: 1,
    lessonNo: 16
  },
  {
    id: "1-33",
    diff: 3,
    question: "遺族厚生年金の額は、原則として、死亡した者の厚生年金保険の被保険者記録を基に計算した老齢厚生年金の報酬比例部分の額の３分の２相当額である。",
    lecture: "原則として報酬比例部分の額の４分の３相当額になります。",
    answer: 0,
    categoryId: 1,
    num: 33,
    type: 1,
    lessonNo: 16
  },
  {
    id: "1-34",
    diff: 3,
    question: "遺族厚生年金と老齢厚生年金の受給権を有している者は、65 歳以降、その者の選択によりいずれか一方の年金が支給され、他方の年金は支給停止となる。",
    lecture: "本人の老齢厚生年金がまず優先して支給され、老齢厚生年金より遺族厚生年金の年金額が多い場合等には、その差額が遺族厚生年金として支給されます。",
    answer: 0,
    categoryId: 1,
    num: 34,
    type: 1,
    lessonNo: 16
  },
  {
    id: "1-35",
    diff: 3,
    question: "確定拠出年金の個人型年金の加入者が国民年金の第３号被保険者である場合、原則として、掛金の拠出限度額は年額 240,000 円（月額 20,000 円）である。",
    lecture: "国民年金の第３号被保険者の拠出限度額は年額 276,000 円（月額 23,000円）です。",
    answer: 0,
    categoryId: 1,
    num: 35,
    type: 1,
    lessonNo: 17
  },
  {
    id: "1-36",
    diff: 3,
    question: "確定拠出年金の個人型年金の通算加入者等期間が 10 年以上ある者が、老齢給付金の支給を受けることができるのは、原則として、65 歳からである。",
    lecture: "原則として 60 歳から受け取ることができます。",
    answer: 0,
    categoryId: 1,
    num: 36,
    type: 1,
    lessonNo: 17
  },
  {
    id: "1-37",
    diff: 2,
    question: "中小企業退職金共済の掛金は、事業主が全額を負担し、掛金月額は、被共済者 1 人当たり 2 万円が上限となっている。",
    lecture: "掛金月額の上限は 1 人当たり 3 万円です。",
    answer: 0,
    categoryId: 1,
    num: 37,
    type: 1,
    lessonNo: 17
  },
  {
    id: "1-38",
    diff: 2,
    question: "国民年金基金の給付には、老齢年金、障害年金、遺族一時金がある。",
    lecture: "国民年金基金の給付は、老齢の年金、遺族一時金はありますが、障害年金はありません。",
    answer: 0,
    categoryId: 1,
    num: 38,
    type: 1,
    lessonNo: 17
  },
  {
    id: "1-39",
    diff: 2,
    question: "小売業を主たる事業として営む個人事業主が、小規模企業共済に加入するためには、常時使用する従業員数が５人以下でなければならない。",
    lecture: null,
    answer: 1,
    categoryId: 1,
    num: 39,
    type: 1,
    lessonNo: 17
  },
  {
    id: "1-40",
    diff: 1,
    question: "財務分析指標の一つである固定比率は、自己資本（株主資本）に対する固定資産の割合を示したものであり、一般に、この数値が高い方が財務の健全性が高いと判断される。",
    lecture: "固定比率は、低い方が財務の健全性が高いと判断されます。",
    answer: 0,
    categoryId: 1,
    num: 40,
    type: 1,
    lessonNo: 18
  },
  {
    id: "2-1",
    diff: 2,
    question: "破綻した少額短期保険業者と締結していた保険契約は、生命保険契約者保護機構または損害保険契約者保護機構による保護の対象となる。",
    lecture: "少額短期保険業者は、保険契約者保護機構の保護の対象外です。",
    answer: 0,
    categoryId: 2,
    num: 1,
    type: 1,
    lessonNo: 2
  },
  {
    id: "2-2",
    diff: 1,
    question: "保険業法上、保険期間が１年以内の保険契約の申込みをした者は、契約の申込日から８日以内であれば、申込みの撤回等をすることができる。",
    lecture: "保険期間 1 年以内の保険契約は、クーリング・オフをすることはできません。その他にも医師の診査を受けた場合も対象外です。",
    answer: 0,
    categoryId: 2,
    num: 2,
    type: 1,
    lessonNo: 2
  },
  {
    id: "2-3",
    diff: 1,
    question: "保険法は、生命保険契約、損害保険契約だけでなく、保険契約と同等の内容を有する共済契約も適用対象となる。",
    lecture: null,
    answer: 1,
    categoryId: 2,
    num: 3,
    type: 1,
    lessonNo: 3
  },
  {
    id: "2-4",
    diff: 2,
    question: "保険料の内訳は、将来の保険金・給付金等の支払いの財源となる純保険料と、保険会社が保険契約を維持・管理していくために必要な経費等の財源となる付加保険料とに分けられる。",
    lecture: null,
    answer: 1,
    categoryId: 2,
    num: 4,
    type: 1,
    lessonNo: 4
  },
  {
    id: "2-5",
    diff: 2,
    question: "10 年保証期間付終身年金では、被保険者の性別以外の契約条件が同一である場合、保険料は女性の方が男性よりも高くなる。",
    lecture: null,
    answer: 1,
    categoryId: 2,
    num: 5,
    type: 1,
    lessonNo: 5
  },
  {
    id: "2-6",
    diff: 2,
    question: "保険期間の経過に伴い保険金額が増加する逓増定期保険は、保険金額の増加に伴い保険料も増加する。",
    lecture: "逓増定期保険の保険金額は、保険期間の経過に伴い所定の割合で増加しますが、保険料は一定です。",
    answer: 0,
    categoryId: 2,
    num: 6,
    type: 1,
    lessonNo: 5
  },
  {
    id: "2-7",
    diff: 3,
    question: "収入保障保険の死亡保険金を年金形式で受け取る場合の受取総額は、一時金で受け取る場合の受取額よりも多くなる。",
    lecture: null,
    answer: 1,
    categoryId: 2,
    num: 7,
    type: 1,
    lessonNo: 5
  },
  {
    id: "2-8",
    diff: 1,
    question: "終身保険の保険料は、被保険者の年齢、死亡保険金額、保険料払込期間など契約内容が同一の場合、一般に、被保険者が女性である方が男性であるよりも高くなる。",
    lecture: "死亡保険は、予定死亡率が低いほど保険料は安くなります。男性に比べ女性の方が長寿ですから、他の条件が同一なら、一般に、女性の方が保険料は安くなります。",
    answer: 0,
    categoryId: 2,
    num: 8,
    type: 1,
    lessonNo: 5
  },
  {
    id: "2-9",
    diff: 2,
    question: "低解約返戻金型終身保険では、他の契約条件が同じで低解約返戻金型ではない終身保険と比較して、保険料払込期間中の解約返戻金が低く抑えられており、割安な保険料が設定されている。",
    lecture: null,
    answer: 1,
    categoryId: 2,
    num: 9,
    type: 1,
    lessonNo: 5
  },
  {
    id: "2-10",
    diff: 3,
    question: "変額保険（終身型）では、契約時に定めた保険金額（基本保険金額）が保証されておらず、運用実績によっては、死亡保険金の額が基本保険金額を下回ることがある。",
    lecture: "変額保険は、契約時に定めた保険金額（基本保険金額）は保証されています。運用実績により死亡保険金が基本保険金額を上回ることはありますが、下回ることはありません。",
    answer: 0,
    categoryId: 2,
    num: 10,
    type: 1,
    lessonNo: 5
  },
  {
    id: "2-11",
    diff: 2,
    question: "定期保険特約付終身保険（更新型）は、定期保険特約を同額の保険金額で更新する場合、被保険者の健康状態についての告知や医師の診査が必要であり、その健康状態によっては更新することができない。",
    lecture: "更新型の定期保険特約を更新する場合、健康状態の告知等は不要です。更新前に入院給付金等の支払いを受けていたとしても、更新はできます。",
    answer: 0,
    categoryId: 2,
    num: 11,
    type: 1,
    lessonNo: 5
  },
  {
    id: "2-12",
    diff: 2,
    question: "定期保険特約付終身保険（更新型）では、定期保険特約を同額の保険金額で自動更新すると、更新後の保険料は、通常、更新前よりも高くなる。",
    lecture: null,
    answer: 1,
    categoryId: 2,
    num: 12,
    type: 1,
    lessonNo: 5
  },
  {
    id: "2-13",
    diff: 3,
    question: "確定年金は、10 年、15 年などの契約時に定めた年金支払期間中に被保険者が死亡した場合、その時点で契約が消滅して年金支払いは終了する。",
    lecture: "確定年金は有期年金と違い、年金支払期間中に被保険者が死亡しても、残りの期間の年金は消滅せず遺族が受け取れます。",
    answer: 0,
    categoryId: 2,
    num: 13,
    type: 1,
    lessonNo: 5
  },
  {
    id: "2-14",
    diff: 2,
    question: "個人年金保険（有期年金）の保険料は、被保険者が同年齢で、基本年金額や保険料払込期間、年金受取開始年齢などの契約内容が同一の場合、個人年金保険（確定年金）よりも高くなる。",
    lecture: "受取期間中に被保険者が死亡すると、有期年金は年金が終了するのに対して、確定年金は残りの期間分は遺族が受け取れます。有期年金の方が受け取れる確率が低くなる分、保険料が安くなります。",
    answer: 0,
    categoryId: 2,
    num: 14,
    type: 1,
    lessonNo: 5
  },
  {
    id: "2-15",
    diff: 3,
    question: "変額個人年金保険は、特別勘定による運用実績によって、将来受け取る年金額や解約返戻金額が変動する。",
    lecture: null,
    answer: 1,
    categoryId: 2,
    num: 15,
    type: 1,
    lessonNo: 5
  },
  {
    id: "2-16",
    diff: 2,
    question: "がん保険では、通常、90 日間または３カ月間の免責期間が設けられており、その期間中に被保険者ががんと診断確定された場合であっても、がん診断給付金は支払われない。",
    lecture: null,
    answer: 1,
    categoryId: 2,
    num: 16,
    type: 1,
    lessonNo: 5
  },
  {
    id: "2-17",
    diff: 2,
    question: "特定（三大）疾病保障定期保険は、悪性新生物・急性心筋梗塞・脳卒中により所定の状態と診断され、特定（三大）疾病保障保険金が支払われたとしても、死亡保険金や高度障害保険金が支払われなければ、保険契約は継続する。",
    lecture: "特定（三大）疾病保障保険は、一度保険金が支払われると契約自体が消滅します。そのため、保険金支払い後に死亡等があっても再び保険金が支払われることはありません。",
    answer: 0,
    categoryId: 2,
    num: 17,
    type: 1,
    lessonNo: 5
  },
  {
    id: "2-18",
    diff: 2,
    question: "「従業員の自助努力による死亡保障の準備を支援したい」という企業に対して、団体信用生命保険の活用をアドバイスした。",
    lecture: "団体信用生命保険は、住宅ローン等の借入時に利用できる保険のため、設問のニーズには合いません。本設に適しているのは団体定期保険（Bグループ保険）です。",
    answer: 0,
    categoryId: 2,
    num: 18,
    type: 1,
    lessonNo: 5
  },
  {
    id: "2-19",
    diff: 2,
    question: "総合福祉団体定期保険のヒューマン・ヴァリュー特約は、従業員の死亡などによる企業の経済的損失に備えるものであり、その特約死亡保険金の受取人は、従業員の遺族ではなく企業となる。",
    lecture: null,
    answer: 1,
    categoryId: 2,
    num: 19,
    type: 1,
    lessonNo: 5
  },
  {
    id: "2-20",
    diff: 1,
    question: "終身保険の月払保険料のうち、本年１月に払い込まれた前年 12 月分の保険料は、本年分の生命保険料控除の対象となる。",
    lecture: null,
    answer: 1,
    categoryId: 2,
    num: 20,
    type: 1,
    lessonNo: 7
  },
  {
    id: "2-21",
    diff: 2,
    question: "養老保険の月払保険料について、保険料の支払いがなかったため、自動振替貸付により保険料の払込みに充当された金額は、生命保険料控除の対象となる。",
    lecture: null,
    answer: 1,
    categoryId: 2,
    num: 21,
    type: 1,
    lessonNo: 7
  },
  {
    id: "2-22",
    diff: 1,
    question: "2011 年 12 月 31 日以前に締結した医療保険契約を 2012 年１月１日以後に更新した場合、更新後の保険料は介護医療保険料控除の対象とならず、一般の生命保険料控除の対象となる。",
    lecture: "2011 年以前に契約した医療保険を 2012 年以降に更新すると、介護医療保険料控除の対象となります。",
    answer: 0,
    categoryId: 2,
    num: 22,
    type: 1,
    lessonNo: 7
  },
  {
    id: "2-23",
    diff: 3,
    question: "契約者および保険金受取人が夫、被保険者が妻である終身保険において、妻が死亡して夫が受け取る死亡保険金は、相続税の課税対象となる。",
    lecture: "契約者と被保険者、受取人の関係により、死亡保険金に対する課税が異なります。契約者と受取人が同じ場合は、一時所得として所得税・住民税の課税対象となります。",
    answer: 0,
    categoryId: 2,
    num: 23,
    type: 1,
    lessonNo: 7
  },
  {
    id: "2-24",
    diff: 3,
    question: "契約者と被保険者、年金受取人が同一人である個人年金保険（保証期間付終身年金）において、保証期間中に被保険者が死亡して遺族が取得した残りの保証期間の年金受給権は、一時所得として所得税の課税対象となる。",
    lecture: "契約者と被保険者が同じである個人年金保険の、保証期間内に被保険者が死亡した場合、残りの保証期間の年金を受け取る権利は、雑所得や一時所得ではなく、相続税の課税対象となります。",
    answer: 0,
    categoryId: 2,
    num: 24,
    type: 1,
    lessonNo: 7
  },
  {
    id: "2-25",
    diff: 2,
    question: "契約者（個人）と被保険者が同一人である医療保険において、被保険者が疾病のため入院治療をしたことにより受け取る入院給付金は、一時所得として課税対象となる。",
    lecture: "入院給付金は、被保険者本人や配偶者など一定の親族が受け取る場合は非課税です。",
    answer: 0,
    categoryId: 2,
    num: 25,
    type: 1,
    lessonNo: 7
  },
  {
    id: "2-26",
    diff: 3,
    question: "契約者が法人、被保険者が役員、死亡保険金受取人及び満期保険金受取人が法人である養老保険の支払保険料は、その２分の１相当額を資産に計上し、残額を損金の額に算入することができる。",
    lecture: "設問の場合、支払保険料の全額が資産計上となります。",
    answer: 0,
    categoryId: 2,
    num: 26,
    type: 1,
    lessonNo: 8
  },
  {
    id: "2-27",
    diff: 3,
    question: "新たに契約する、被保険者が役員、死亡保険金受取人が法人で、最高解約返戻率が 60％である定期保険（保険期間 20 年、年払保険料 100 万円）の支払保険料は、保険期間の前半 100 分の 40 相当期間においては、その 40％相当額を限度に損金の額に算入することができる。",
    lecture: "設問のような定期保険では、保険期間の前半 4 割（100 分の 40）の期間は、支払保険料の 60%が損金算入で、40%が資産計上です。",
    answer: 0,
    categoryId: 2,
    num: 27,
    type: 1,
    lessonNo: 8
  },
  {
    id: "2-28",
    diff: 2,
    question: "被保険者が役員、死亡保険金受取人が法人である終身保険を法人が解約して受け取った解約返戻金は、その全額を益金の額に算入する。",
    lecture: "法人が解約返戻金を受け取った場合、資産計上していた保険料積立金等を差し引きし、マイナス（解約返戻金が少ない）の場合は差額を雑損失に、プラスなら差額を雑収入とします。",
    answer: 0,
    categoryId: 2,
    num: 28,
    type: 1,
    lessonNo: 8
  },
  {
    id: "2-29",
    diff: 2,
    question: "失火の責任に関する法律によれば、失火により他人に損害を与えた場合、その失火者に重大な過失がなかったときは、民法第 709 条（不法行為による損害賠償）の規定が適用される 。",
    lecture: "失火責任法によれば、故意や重過失による失火でなければ民法上の不法行為責任の規定は適用されません。",
    answer: 0,
    categoryId: 2,
    num: 29,
    type: 1,
    lessonNo: 10
  },
  {
    id: "2-30",
    diff: 1,
    question: "住宅用建物及び家財を保険の対象とする火災保険では、住宅用建物を保険の対象として契約した場合、時間の経過によりその建物の壁に発生したカビによる損害は補償の対象となる。",
    lecture: "火災保険では、経年劣化や、時間の経過により発生するカビやシロアリ等による損害は対象外です。",
    answer: 0,
    categoryId: 2,
    num: 30,
    type: 1,
    lessonNo: 10
  },
  {
    id: "2-31",
    diff: 2,
    question: "被保険者が被保険自動車を運転中に、ハンドル操作を誤って路上にいる自分の子にケガを負わせた場合、対人賠償保険の補償の対象となる。",
    lecture: "対人賠償保険や対物賠償保険は、他人を死傷させたり、他人の財物に損害を与えたときに補償されます。そのため自分の子や親等に与えた損害への補償は対象外です。",
    answer: 0,
    categoryId: 2,
    num: 31,
    type: 1,
    lessonNo: 10
  },
  {
    id: "2-32",
    diff: 3,
    question: "被保険自動車を運転中に交通事故で被保険者が死亡した場合、その損害のうち、被保険者自身の過失割合に相当する部分を差し引いた損害が人身傷害補償保険の補償の対象となる。",
    lecture: "人身傷害補償保険は、自身の過失割合に関わらず、保険金額の範囲内で損害額について保険金が支払われるのが特徴であり、過失割合に相当する部分は差し引かれません。",
    answer: 0,
    categoryId: 2,
    num: 32,
    type: 1,
    lessonNo: 10
  },
  {
    id: "2-33",
    diff: 3,
    question: "普通傷害保険（特約なし）では、地震・噴火またはこれらによる津波によって生じたケガは補償の対象となる。",
    lecture: "地震・噴火またはこれらによる津波によるケガは、普通傷害保険や国内旅行傷害保険では特約がない限り対象になりません。",
    answer: 0,
    categoryId: 2,
    num: 33,
    type: 1,
    lessonNo: 10
  },
  {
    id: "2-34",
    diff: 3,
    question: "家族傷害保険では、記名被保険者またはその配偶者と生計を共にする別居の未婚の子は被保険者となる。",
    lecture: null,
    answer: 1,
    categoryId: 2,
    num: 34,
    type: 1,
    lessonNo: 10
  },
  {
    id: "2-35",
    diff: 2,
    question: "所得補償保険は、被保険者が保険会社所定の病気により就業不能になった場合には補償の対象となるが、ケガにより就業不能になった場合には補償の対象とならない。",
    lecture: "所得補償保険は、病気のみならず、ケガによる就業不能も補償対象です。なお、入院が伴わなくとも補償されます。",
    answer: 0,
    categoryId: 2,
    num: 35,
    type: 1,
    lessonNo: 10
  },
  {
    id: "2-36",
    diff: 3,
    question: "レストランを営む事業者が、食中毒を発生させ、顧客に対して法律上の損害賠償責任を負うリスクに備えるため、生産物賠償責任保険（ＰＬ保険）に加入することを検討している。",
    lecture: null,
    answer: 1,
    categoryId: 2,
    num: 36,
    type: 1,
    lessonNo: 10
  },
  {
    id: "2-37",
    diff: 2,
    question: "清掃業務を請け負っている事業者が、清掃業務中の事故により従業員がケガをして、法律上の損害賠償責任を負担する場合に備えて、請負業者賠償責任保険を契約した。",
    lecture: "請負業者賠償責任保険では、従業員のケガへの補償はされません。設問のリスクで公的な労災保険の上乗せとして備えるには労働災害総合保険が適切です。",
    answer: 0,
    categoryId: 2,
    num: 37,
    type: 1,
    lessonNo: 10
  },
  {
    id: "2-38",
    diff: 3,
    question: "契約者（＝保険料負担者）及び被保険者を同一の個人とする損害保険の場合、契約者が受け取る保険期間 10 年の積立火災保険の満期返戻金は、一時所得として課税対象となる。",
    lecture: null,
    answer: 1,
    categoryId: 2,
    num: 38,
    type: 1,
    lessonNo: 11
  },
  {
    id: "2-39",
    diff: 2,
    question: "地震保険料控除の控除限度額は、所得税において 50,000 円であり、年間支払保険料の２分の１の金額が控除される。",
    lecture: "地震保険料控除における、所得税の控除限度額は最高 50,000 円で、支払保険料の全額が控除対象です。支払保険料の 2 分の 1 の金額（最高 2 万 5,000円）が控除されるのは住民税です。",
    answer: 0,
    categoryId: 2,
    num: 39,
    type: 1,
    lessonNo: 11
  },
  {
    id: "2-40",
    diff: 3,
    question: "契約者（＝保険料負担者）が法人の場合で、法人が所有する建物が火災で焼失し、受け取った火災保険の保険金で同一事業年度に代替の建物を取得した場合、所定の要件にもとづき圧縮記帳が認められる。",
    lecture: null,
    answer: 1,
    categoryId: 2,
    num: 40,
    type: 1,
    lessonNo: 11
  },
  {
    id: "3-1",
    diff: 3,
    question: "国内総生産（支出側）の構成項目のうち、民間最終消費支出は、最も高い構成比を占めている。",
    lecture: null,
    answer: 1,
    categoryId: 3,
    num: 1,
    type: 1,
    lessonNo: 1
  },
  {
    id: "3-2",
    diff: 3,
    question: "経済成長率には名目値と実質値があり、名目値が上昇していても、実質値は下落することがある。",
    lecture: null,
    answer: 1,
    categoryId: 3,
    num: 2,
    type: 1,
    lessonNo: 1
  },
  {
    id: "3-3",
    diff: 3,
    question: "景気動向指数は、生産、雇用などさまざまな経済活動での重要かつ景気に敏感に反応する指標の動きを統合することによって作成された指標であり、コンポジット・インデックス（CI）を中心として公表される。",
    lecture: null,
    answer: 1,
    categoryId: 3,
    num: 3,
    type: 1,
    lessonNo: 1
  },
  {
    id: "3-4",
    diff: 2,
    question: "景気動向指数において、東証株価指数は、景気の動きに対してほぼ一致して動く、一致系列に分類されている。",
    lecture: "景気動向指数において、東証株価指数は、景気に先行して動く「先行指数」に分類されています。",
    answer: 0,
    categoryId: 3,
    num: 4,
    type: 1,
    lessonNo: 1
  },
  {
    id: "3-5",
    diff: 3,
    question: "日銀短観の調査は毎月実施され、翌月（12 月は当月）に調査結果が公表される。",
    lecture: "日銀短観は、年４回（3 月・6 月・9 月・12 月）に調査しています。",
    answer: 0,
    categoryId: 3,
    num: 5,
    type: 1,
    lessonNo: 1
  },
  {
    id: "3-6",
    diff: 3,
    question: "マネーストック統計は、金融部門から経済全体に供給されている通貨の総量を示す統計であり、一般法人、金融機関、個人、中央政府、地方公共団体などの経済主体が保有する通貨量の残高を集計したものである。",
    lecture: "国（中央政府）や金融機関が保有する通貨量は含まれません。",
    answer: 0,
    categoryId: 3,
    num: 6,
    type: 1,
    lessonNo: 1
  },
  {
    id: "3-7",
    diff: 3,
    question: "米国の物価が日本と比較して相対的に上昇することは、一般に、円安米ドル高の要因となる。",
    lecture: "米国の物価が日本と比較して上昇することは米ドルの価値が相対的に下がることを意味するため、一般に円高米ドル安になりやすいとされています。",
    answer: 0,
    categoryId: 3,
    num: 7,
    type: 1,
    lessonNo: 2
  },
  {
    id: "3-8",
    diff: 3,
    question: "国内銀行に預け入れられている外貨預金は、元本 1,000 万円までとその利息が預金保険制度による保護の対象となる。",
    lecture: "国内銀行に預けられている外貨預金は、預金保険制度の対象ではありません。",
    answer: 0,
    categoryId: 3,
    num: 8,
    type: 1,
    lessonNo: 4
  },
  {
    id: "3-9",
    diff: 3,
    question: "国内に本店のある銀行で購入した投資信託は、日本投資者保護基金による補償の対象とならない。",
    lecture: null,
    answer: 1,
    categoryId: 3,
    num: 9,
    type: 1,
    lessonNo: 4
  },
  {
    id: "3-10",
    diff: 2,
    question: "消費者契約法では、事業者の不適切な勧誘により締結した消費者契約について、消費者は、同法に基づく損害賠償を請求することができるとされている。",
    lecture: "事業者の不適切な勧誘により締結した消費者契約について、消費者はその契約を取り消すことができます。消費者契約法に損害賠償請求できる規定はありません。",
    answer: 0,
    categoryId: 3,
    num: 10,
    type: 1,
    lessonNo: 5
  },
  {
    id: "3-11",
    diff: 3,
    question: "金融商品取引法では、金融商品取引契約を締結しようとする金融商品取引業者等は、あらかじめ顧客（特定投資家を除く）に契約締結前交付書面を交付しなければならないとされているが、顧客から交付を要しない旨の意思表示があった場合、その交付義務は免除される。",
    lecture: "一般投資家である顧客から交付不要の申出があった場合でも、交付義務は免除されません。",
    answer: 0,
    categoryId: 3,
    num: 11,
    type: 1,
    lessonNo: 5
  },
  {
    id: "3-12",
    diff: 2,
    question: "犯罪収益移転防止法では、金融機関等の特定事業者が顧客と特定業務に係る取引を行った場合、特定事業者は、原則として、直ちに当該取引に関する記録を作成し、当該取引の行われた日から 5 年間保存しなければならないとされている。",
    lecture: "取引日から 7 年間の保存が必要です。",
    answer: 0,
    categoryId: 3,
    num: 12,
    type: 1,
    lessonNo: 5
  },
  {
    id: "3-13",
    diff: 3,
    question: "期日指定定期預金は、据置期間経過後から最長預入期日までの間で、金融機関が指定した日が満期日となる。",
    lecture: "預金者が任意の日を満期日として指定できますが、指定しない場合は、最長預入期日に一括して払戻しされます。",
    answer: 0,
    categoryId: 3,
    num: 13,
    type: 1,
    lessonNo: 6
  },
  {
    id: "3-14",
    diff: 3,
    question: "アンダーパー発行の債券を発行時に購入した場合、償還時に償還差損が発生する。",
    lecture: "アンダーパー発行とは、発行価格が額面金額を下回るため、償還差益が発生します。",
    answer: 0,
    categoryId: 3,
    num: 14,
    type: 1,
    lessonNo: 7
  },
  {
    id: "3-15",
    diff: 2,
    question: "債券のデュレーションは、他の条件が同一であれば、表面利率が高いほどデュレーションは長くなる。",
    lecture: "債券のデュレーションは他の条件が同一の場合、表面利率が低いほどデュレーションは長くなります。",
    answer: 0,
    categoryId: 3,
    num: 15,
    type: 1,
    lessonNo: 7
  },
  {
    id: "3-16",
    diff: 3,
    question: "固定利付債券（個人向け国債を除く）では、一般的に、市場金利の上昇は債券価格の上昇要因となり、市場金利の低下は債券価格の下落要因となる。",
    lecture: "金利が上がると債券価格は下がります。市場金利と債券利回りは同じ動きですが、市場金利と債券価格は反対の動きになります。",
    answer: 0,
    categoryId: 3,
    num: 16,
    type: 1,
    lessonNo: 7
  },
  {
    id: "3-17",
    diff: 2,
    question: "債券を発行体の信用度で比較した場合、ほかの条件が同じであれば、発行体の信用度が高い債券の方が価格は低い。",
    lecture: "ほかの条件が同じであれば、一般的には発行体の信用度が高い債券の方が人気が高くなりますので、債券の価格は高く、利回りは低くなります。",
    answer: 0,
    categoryId: 3,
    num: 17,
    type: 1,
    lessonNo: 7
  },
  {
    id: "3-18",
    diff: 2,
    question: "株式の信用取引は、保有していない銘柄であっても、「売り」から取引することができる",
    lecture: null,
    answer: 1,
    categoryId: 3,
    num: 18,
    type: 1,
    lessonNo: 8
  },
  {
    id: "3-19",
    diff: 1,
    question: "JPX 日経インデックス 400 は、プライム市場、スタンダード市場、グロース市場を主市場とする普通株式の中から、ROE や営業利益等の指標等により選定された 400 銘柄を対象として算出される。",
    lecture: null,
    answer: 1,
    categoryId: 3,
    num: 19,
    type: 1,
    lessonNo: 9
  },
  {
    id: "3-20",
    diff: 3,
    question: "株価が 2,500 円、当期純利益が 12 億円、自己資本が 300 億円、発行株式数が 600 万株である場合、この株式のPERは 12.5 倍である。",
    lecture: null,
    answer: 1,
    categoryId: 3,
    num: 20,
    type: 1,
    lessonNo: 9
  },
  {
    id: "3-21",
    diff: 3,
    question: "ROEは、自己資本に対する当期純利益の割合を示す投資指標であり、これが低いほど、会社が自己資本を活用して効率良く利益をあげていることを示す。",
    lecture: "ROEは高いほど、会社が自己資本を活用して効率よく利益をあげていることを示します。",
    answer: 0,
    categoryId: 3,
    num: 21,
    type: 1,
    lessonNo: 9
  },
  {
    id: "3-22",
    diff: 3,
    question: "配当利回り（％）は、「配当金総額÷純資産× 100」の算式により計算され、この値が高いほど投資価値が高いと考えられる。",
    lecture: "配当利回り(％)は、「 1 株当たりの年間配当金÷株価× 100」で算出します。本問で示しているのは純資産配当率（会社の自己資本からどれだけ配当に回っているか）です。",
    answer: 0,
    categoryId: 3,
    num: 22,
    type: 1,
    lessonNo: 9
  },
  {
    id: "3-23",
    diff: 3,
    question: "ベンチマークを上回る運用成果を目指す株式投資信託の運用手法は、パッシブ運用と呼ばれる。",
    lecture: "ベンチマークを上回る運用成果を目指す株式投資信託の運用手法は、アクティブ運用です。",
    answer: 0,
    categoryId: 3,
    num: 23,
    type: 1,
    lessonNo: 10
  },
  {
    id: "3-24",
    diff: 2,
    question: "マクロ的な環境要因などをもとに国別組入比率や業種別組入比率などを決定し、その比率に応じて、個別銘柄を組み入れてポートフォリオを構築する手法をボトムアップアプローチという。",
    lecture: "本問のポートフォリオ構築手法は、トップダウンアプローチといいます。",
    answer: 0,
    categoryId: 3,
    num: 24,
    type: 1,
    lessonNo: 10
  },
  {
    id: "3-25",
    diff: 2,
    question: "ETFには、株価指数に連動するものはあるが、REIT 指数や商品指数に連動するものはない。",
    lecture: "インデックス型の ETF には、REIT 指数、商品指数のほか債券の指数など、さまざまな指数に連動するものがあります。また、アクティブ型もあります。",
    answer: 0,
    categoryId: 3,
    num: 25,
    type: 1,
    lessonNo: 10
  },
  {
    id: "3-26",
    diff: 2,
    question: "ETFは、売買の際に上場株式と同様に売買委託手数料が発生するが、非上場の投資信託と異なり、運用管理費用（信託報酬）は発生しない。",
    lecture: "ETFも運用管理費用はかかります。",
    answer: 0,
    categoryId: 3,
    num: 26,
    type: 1,
    lessonNo: 10
  },
  {
    id: "3-27",
    diff: 3,
    question: "為替先物予約をしていない外貨定期預金の満期時の為替レートが預入時の為替レートに比べて円高になれば、当該外貨定期預金に係る円換算の投資利回りは向上する。",
    lecture: "為替先物予約をしていない外貨預金は、円高（外貨安）になれば、投資利回りは低下します。",
    answer: 0,
    categoryId: 3,
    num: 27,
    type: 1,
    lessonNo: 11
  },
  {
    id: "3-28",
    diff: 2,
    question: "デュアルカレンシー債は、購入代金の払込みおよび利払いの通貨と、償還される通貨が異なる債券である。",
    lecture: null,
    answer: 1,
    categoryId: 3,
    num: 28,
    type: 1,
    lessonNo: 11
  },
  {
    id: "3-29",
    diff: 2,
    question: "国内の証券取引所に上場している外国株式を、国内店頭取引により売買する場合、外国証券取引口座を開設する必要がある。",
    lecture: null,
    answer: 1,
    categoryId: 3,
    num: 29,
    type: 1,
    lessonNo: 11
  },
  {
    id: "3-30",
    diff: 2,
    question: "ポートフォリオのリスクは、組入れ銘柄数を増やすことで減少していくが、組入れ銘柄数が一定水準以上になると、銘柄数を増やしてもリスクが減少しにくくなる。",
    lecture: null,
    answer: 1,
    categoryId: 3,
    num: 30,
    type: 1,
    lessonNo: 12
  },
  {
    id: "3-31",
    diff: 3,
    question: "異なる２資産からなるポートフォリオの相関係数が−１の場合、ポートフォリオを組成することによる分散投資の効果（リスクの軽減）は得られない。",
    lecture: "相関係数が「−１」の場合は、分散投資の効果は最大になります。",
    answer: 0,
    categoryId: 3,
    num: 31,
    type: 1,
    lessonNo: 12
  },
  {
    id: "3-32",
    diff: 3,
    question: "シャープレシオは、数値が小さいほど、効率よく収益をあげられたと評価できる。",
    lecture: "分母がリスク（標準偏差）、分子がリスクに応じたリターン（ポートフォリオの収益率−無リスク資産利子率）ですので、数値が大きいほど、効率よく収益をあげられたと評価できます。",
    answer: 0,
    categoryId: 3,
    num: 32,
    type: 1,
    lessonNo: 12
  },
  {
    id: "3-33",
    diff: 2,
    question: "運用期間を通して、定められた各資産クラスの投資金額の配分比率を維持する方法の一つとして、値上がりした資産クラスを売却し、値下がりした資産クラスを購入するリバランスという方法がある。",
    lecture: null,
    answer: 1,
    categoryId: 3,
    num: 33,
    type: 1,
    lessonNo: 12
  },
  {
    id: "3-34",
    diff: 2,
    question: "わが国における個人によるデリバティブ取引のオプション取引では、オプションの買い手の損失は限定されないが、オプションの売り手の損失はプレミアム（オプション料）に限定される。",
    lecture: "オプションの買い手の損失はプレミアム（オプション料）に限定され、オプションの売り手の損失は限定されません。",
    answer: 0,
    categoryId: 3,
    num: 34,
    type: 1,
    lessonNo: 12
  },
  {
    id: "3-35",
    diff: 3,
    question: "オプション取引において、コール・オプションの買い手は「権利行使価格で買う権利」を放棄することができるが、プット・オプションの買い手は「権利行使価格で売る権利」を放棄することができない。",
    lecture: "コール、プットいずれの場合も、オプションの買い手は権利行使を放棄できます。売り手は権利放棄できず、買い手の権利行使に応じる義務があります。",
    answer: 0,
    categoryId: 3,
    num: 35,
    type: 1,
    lessonNo: 12
  },
  {
    id: "3-36",
    diff: 3,
    question: "ヘッジ取引には、将来の価格上昇リスク等を回避または軽減する売りヘッジと将来の価格下落リスク等を回避または軽減する買いヘッジがある。",
    lecture: "将来の価格上昇リスク等を回避または軽減するのは買いヘッジ、将来の価格下落リスク等を回避または軽減するのは売りヘッジです。",
    answer: 0,
    categoryId: 3,
    num: 36,
    type: 1,
    lessonNo: 12
  },
  {
    id: "3-37",
    diff: 2,
    question: "特定口座（源泉徴収口座）を選択している場合、上場株式等の譲渡損失を翌年以降に繰り越すためには確定申告は必要ない。",
    lecture: "特定口座（源泉徴収口座）を選択している場合であっても、上場株式等の譲渡損失を翌年以降に繰り越すためには確定申告は必要です。",
    answer: 0,
    categoryId: 3,
    num: 37,
    type: 1,
    lessonNo: 13
  },
  {
    id: "3-38",
    diff: 2,
    question: "上場株式の配当金について申告分離課税を選択して確定申告をした場合、配当控除の適用を受けることができる。",
    lecture: "配当控除を受けられるのは、総合課税を選択した場合のみです。",
    answer: 0,
    categoryId: 3,
    num: 38,
    type: 1,
    lessonNo: 13
  },
  {
    id: "3-39",
    diff: 3,
    question: "新 NISA のつみたて投資枠を通じて購入することができる金融商品は、所定の要件を満たす公募株式投資信託や ETF（上場投資信託）であり、長期の積立・分散投資に適した一定の商品性を有するものに限られている。",
    lecture: null,
    answer: 1,
    categoryId: 3,
    num: 39,
    type: 1,
    lessonNo: 13
  },
  {
    id: "3-40",
    diff: 2,
    question: "新 NISA 口座で保有する上場株式の配当金を非課税扱いにするためには、配当金の受取方法として、株式数比例配分方式を選択しなければならない。",
    lecture: null,
    answer: 1,
    categoryId: 3,
    num: 40,
    type: 1,
    lessonNo: 13
  },
  {
    id: "4-1",
    diff: 2,
    question: "税金には国税と地方税があるが、不動産取得税は国税に該当し、固定資産税は地方税に該当する。",
    lecture: "不動産取得税は固定資産税と同様に、地方税に該当します。",
    answer: 0,
    categoryId: 4,
    num: 1,
    type: 1,
    lessonNo: 1
  },
  {
    id: "4-2",
    diff: 2,
    question: "税金を負担する者と税金を納める者が異なる税金を間接税といい、相続税は間接税に該当する。",
    lecture: "相続税は直接税に該当します。",
    answer: 0,
    categoryId: 4,
    num: 2,
    type: 1,
    lessonNo: 1
  },
  {
    id: "4-3",
    diff: 2,
    question: "年金受給者が受け取った遺族基礎年金は、所得税における非課税所得となる。",
    lecture: null,
    answer: 1,
    categoryId: 4,
    num: 3,
    type: 1,
    lessonNo: 1
  },
  {
    id: "4-4",
    diff: 2,
    question: "給与所得者が受け取った健康保険の傷病手当金は、給与所得として所得税の課税対象となる。",
    lecture: "健康保険や雇用保険からの各種手当金や給付金は、非課税所得です。",
    answer: 0,
    categoryId: 4,
    num: 4,
    type: 1,
    lessonNo: 1
  },
  {
    id: "4-5",
    diff: 2,
    question: "所得税額の計算において課税総所得金額に乗じる税率には、課税総所得金額が大きくなるにつれて段階的に税率が高くなる超過累進税率が採用されており、その最高税率は 30％である。",
    lecture: "超過累進税率の最高税率は 45％です。",
    answer: 0,
    categoryId: 4,
    num: 5,
    type: 1,
    lessonNo: 1
  },
  {
    id: "4-6",
    diff: 1,
    question: "合計所得金額は、損益通算後の各種所得の金額の合計額から、純損失や雑損失の繰越控除を適用した後の金額である。",
    lecture: "合計所得金額は、損益通算後で純損失や雑損失の繰越控除を適用する前の金額です。",
    answer: 0,
    categoryId: 4,
    num: 6,
    type: 1,
    lessonNo: 1
  },
  {
    id: "4-7",
    diff: 2,
    question: "個人事業主が事業資金で購入した株式について、配当金を受け取ったことによる所得は、配当所得となる。",
    lecture: null,
    answer: 1,
    categoryId: 4,
    num: 7,
    type: 1,
    lessonNo: 2
  },
  {
    id: "4-8",
    diff: 2,
    question: "不動産の貸付けを事業的規模で行ったことにより生じた賃貸収入による所得は、事業所得となる。",
    lecture: "不動産の貸付を事業的規模（5 棟 10 室以上）で行っている場合でも、その賃貸料収入は不動産所得です。",
    answer: 0,
    categoryId: 4,
    num: 8,
    type: 1,
    lessonNo: 2
  },
  {
    id: "4-9",
    diff: 2,
    question: "給与所得の金額は、原則として、「給与等の収入金額－給与所得控除額」の算式により計算される。",
    lecture: null,
    answer: 1,
    categoryId: 4,
    num: 9,
    type: 1,
    lessonNo: 2
  },
  {
    id: "4-10",
    diff: 1,
    question: "退職一時金を受け取った退職者が、「退職所得の受給に関する申告書」を提出している場合、所得税および復興特別所得税として、退職一時金の支給額の 20.42％が源泉徴収される 。",
    lecture: "退職一時金に対して 20.42%が源泉徴収されるのは、「退職所得の受給に関する申告書」を提出していない場合です。",
    answer: 0,
    categoryId: 4,
    num: 10,
    type: 1,
    lessonNo: 2
  },
  {
    id: "4-11",
    diff: 1,
    question: "ゴルフ会員権を譲渡したことによる所得は、申告分離課税の対象となる。",
    lecture: "ゴルフ会員権の譲渡所得は、総合課税の対象です。",
    answer: 0,
    categoryId: 4,
    num: 11,
    type: 1,
    lessonNo: 2
  },
  {
    id: "4-12",
    diff: 2,
    question: "収入のない専業主婦（夫）が金地金を売却したことによる所得は、譲渡所得となる。",
    lecture: null,
    answer: 1,
    categoryId: 4,
    num: 12,
    type: 1,
    lessonNo: 2
  },
  {
    id: "4-13",
    diff: 3,
    question: "一時所得の金額は、「一時所得に係る総収入金額－その収入を得るために支出した金額の合計額」の算式により計算される。",
    lecture: "一時所得は、特別控除があるので計算式に「－特別控除（最高 50 万円）」が入ります。なお、一時所得は総合課税であり、損益通算後の一時所得を１／２して、総所得金額に算入します。",
    answer: 0,
    categoryId: 4,
    num: 13,
    type: 1,
    lessonNo: 2
  },
  {
    id: "4-14",
    diff: 3,
    question: "年金受給者が受け取った老齢基礎年金は、雑所得として所得税の課税対象となる。",
    lecture: null,
    answer: 1,
    categoryId: 4,
    num: 14,
    type: 1,
    lessonNo: 2
  },
  {
    id: "4-15",
    diff: 2,
    question: "コンサルティング事業を行ったことによる事業所得の金額の計算上生じた損失の金額は、不動産所得の金額と損益通算することができる。",
    lecture: null,
    answer: 1,
    categoryId: 4,
    num: 15,
    type: 1,
    lessonNo: 3
  },
  {
    id: "4-16",
    diff: 2,
    question: "不動産所得の金額の計算上生じた損失の金額のうち、その不動産所得を生ずべき土地の取得に要した負債の利子の額に相当する部分の金額は、事業所得の金額と損益通算することができない。",
    lecture: null,
    answer: 1,
    categoryId: 4,
    num: 16,
    type: 1,
    lessonNo: 3
  },
  {
    id: "4-17",
    diff: 3,
    question: "上場株式を譲渡したことによる譲渡所得の金額の計算上生じた損失の金額は、総合課税を選択した上場株式の配当所得の金額と損益通算することができない。",
    lecture: null,
    answer: 1,
    categoryId: 4,
    num: 17,
    type: 1,
    lessonNo: 3
  },
  {
    id: "4-18",
    diff: 1,
    question: "納税者の合計所得金額が 2,400 万円以下である場合、所得税における基礎控除の額は 48 万円である。",
    lecture: null,
    answer: 1,
    categoryId: 4,
    num: 18,
    type: 1,
    lessonNo: 4
  },
  {
    id: "4-19",
    diff: 2,
    question: "青色申告者である納税者が、生計を一にする配偶者に支払った青色事業専従者給与が年間 100 万円である場合、納税者は配偶者控除の適用を受けることができる。",
    lecture: "青色事業専従者である配偶者に給与を支払っている場合、その配偶者については、配偶者控除や配偶者特別控除の適用を受けることはできません。",
    answer: 0,
    categoryId: 4,
    num: 19,
    type: 1,
    lessonNo: 4
  },
  {
    id: "4-20",
    diff: 2,
    question: "婚姻の届出を提出していない場合であっても、納税者が加入している健康保険の被扶養者となっており、内縁関係にあると認められる者は、他の要件を満たせば、控除対象配偶者に該当する。",
    lecture: "控除対象配偶者に該当するのは、所得要件等を満たし、婚姻関係にある者です。社会保険とは異なり、内縁関係にあると認められる者でも控除対象にはなりません。",
    answer: 0,
    categoryId: 4,
    num: 20,
    type: 1,
    lessonNo: 4
  },
  {
    id: "4-21",
    diff: 1,
    question: "収入のない配偶者を有する納税者は、配偶者控除と配偶者特別控除を重複して適用を受けることができる。",
    lecture: "配偶者控除が使えない場合に配偶者特別控除が使えることがあるというように、２つの控除は連続的なつながりをもっており、重複して適用を受けることはできません。",
    answer: 0,
    categoryId: 4,
    num: 21,
    type: 1,
    lessonNo: 4
  },
  {
    id: "4-22",
    diff: 2,
    question: "控除対象扶養親族のうち、その年の 12 月 31 日時点の年齢が 19 歳以上 23歳未満の者を特定扶養親族といい、その者に係る所得税における扶養控除の額は 58 万円である。",
    lecture: "特定扶養親族の年齢についての説明は正しいですが、所得税における控除額は 63 万円です。",
    answer: 0,
    categoryId: 4,
    num: 22,
    type: 1,
    lessonNo: 4
  },
  {
    id: "4-23",
    diff: 1,
    question: "老人控除対象配偶者とは、控除対象配偶者のうち、その年の 12 月 31 日現在の年齢が 70 歳以上の者をいう。",
    lecture: null,
    answer: 1,
    categoryId: 4,
    num: 23,
    type: 1,
    lessonNo: 4
  },
  {
    id: "4-24",
    diff: 2,
    question: "納税者が自己の負担すべき社会保険料を支払った場合、支払った社会保険料の金額にかかわらず、その年中に支払った金額の全額を社会保険料控除として控除することができる。",
    lecture: null,
    answer: 1,
    categoryId: 4,
    num: 24,
    type: 1,
    lessonNo: 4
  },
  {
    id: "4-25",
    diff: 3,
    question: "納税者が自宅に係る地震保険の保険料を支払った場合、所得税では支払った保険料の金額にかかわらず、その年中に支払った金額の全額を地震保険料控除として控除することができる。",
    lecture: "所得税における地震保険料控除の対象になる地震保険料は、支払った金額の全額（最高 50,000 円）です。",
    answer: 0,
    categoryId: 4,
    num: 25,
    type: 1,
    lessonNo: 4
  },
  {
    id: "4-26",
    diff: 2,
    question: "住宅ローン控除の対象となる家屋は、床面積が 30 ㎡以上 330 ㎡以下でなければならない。",
    lecture: "住宅ローン控除の対象になる家屋の要件に、床面積は原則 50㎡以上（一定の場合 40㎡以上）と定められていますが上限はありません。",
    answer: 0,
    categoryId: 4,
    num: 26,
    type: 1,
    lessonNo: 5
  },
  {
    id: "4-27",
    diff: 3,
    question: "住宅ローン控除の対象となる家屋は、納税者がもっぱら居住の用に供する家屋に限られ、店舗併用住宅は対象とならない。",
    lecture: "住宅ローン控除の対象になる家屋には、店舗併用住宅も含まれますが、床面積の２分の１以上が自己の居住用であることが要件です。",
    answer: 0,
    categoryId: 4,
    num: 27,
    type: 1,
    lessonNo: 5
  },
  {
    id: "4-28",
    diff: 1,
    question: "確定申告書を提出した納税者が、法定申告期限後に計算の誤りにより所得税を過大に申告していたことに気づいた場合、原則として、法定申告期限から５年以内に限り、更正の請求をすることができる。",
    lecture: null,
    answer: 1,
    categoryId: 4,
    num: 28,
    type: 1,
    lessonNo: 6
  },
  {
    id: "4-29",
    diff: 1,
    question: "青色申告者は、総勘定元帳その他一定の帳簿を起算日から 10 年間、住所地もしくは居所地または事業所等に保存しなければならない。",
    lecture: "青色申告者は、一定の帳簿を、帳簿の起算日（確定申告書の提出期限の翌日）から原則、7 年間保存しなければなりません。",
    answer: 0,
    categoryId: 4,
    num: 29,
    type: 1,
    lessonNo: 6
  },
  {
    id: "4-30",
    diff: 3,
    question: "本年９月に新たに事業を開始した者が、その年分の所得税から青色申告の適用を受けようとする場合には、翌年３月 15 日までに「青色申告承認申請書」を納税地の所轄税務署長に提出し、その承認を受けなければならない。",
    lecture: "新たに青色申告の適用を受けようとするときで、１月 16 日以降の新規開業者は開業から 2 カ月以内に青色申告承認申請書を提出しなければなりません。",
    answer: 0,
    categoryId: 4,
    num: 30,
    type: 1,
    lessonNo: 6
  },
  {
    id: "4-31",
    diff: 1,
    question: "個人事業税の課税標準の計算上、事業主控除として最高 390 万円を控除することができる。",
    lecture: "個人事業税の事業主控除額は、最高 290 万円です。",
    answer: 0,
    categoryId: 4,
    num: 31,
    type: 1,
    lessonNo: 7
  },
  {
    id: "4-32",
    diff: 2,
    question: "損益計算書は、企業の資金の調達源泉とその用途を示したものである。",
    lecture: "設問は貸借対照表の説明です。損益計算書は、会計期間の損益状況を表したものです。",
    answer: 0,
    categoryId: 4,
    num: 32,
    type: 1,
    lessonNo: 8
  },
  {
    id: "4-33",
    diff: 2,
    question: "期末資本金の額等が１億円以下の一定の中小法人が支出した交際費等の額のうち、定額控除限度額である年 800 万円と接待飲食費の額の２分の１相当額のいずれか少ない額が損金算入限度額となる。",
    lecture: "設問の中小法人が支出した交際費等のうち、損金算入できる限度は、年 800万円と接待飲食費の 2 分の 1 相当額を比べていずれか多い額です。",
    answer: 0,
    categoryId: 4,
    num: 33,
    type: 1,
    lessonNo: 8
  },
  {
    id: "4-34",
    diff: 3,
    question: "法人が納付した法人税の本税および法人住民税の本税は、その全額を損金の額に算入することができる。",
    lecture: "法人税法上、損金算入できる租税公課に、法人事業税は含まれますが、法人税や法人住民税の本税は含まれません。",
    answer: 0,
    categoryId: 4,
    num: 34,
    type: 1,
    lessonNo: 8
  },
  {
    id: "4-35",
    diff: 3,
    question: "会社が所有する資産を適正な時価よりも低い価額で役員に譲渡した場合、その適正な時価と譲渡価額との差額が、その役員の給与所得の収入金額となる。",
    lecture: null,
    answer: 1,
    categoryId: 4,
    num: 35,
    type: 1,
    lessonNo: 8
  },
  {
    id: "4-36",
    diff: 3,
    question: "役員が所有する土地を低額で会社に譲渡した場合、その適正な時価の２分の１相当額が会社の受贈益として益金の額に算入される。",
    lecture: "会社側は適正な時価で譲渡されたものとみなされ、時価との差額が受贈益として益金の額に算入されます。",
    answer: 0,
    categoryId: 4,
    num: 36,
    type: 1,
    lessonNo: 8
  },
  {
    id: "4-37",
    diff: 3,
    question: "役員が会社に無利息で金銭の貸付けを行った場合、原則として、通常収受すべき利息に相当する金額が、その役員の雑所得の収入金額となる。",
    lecture: "設問の貸付においては、役員への課税はありません。",
    answer: 0,
    categoryId: 4,
    num: 37,
    type: 1,
    lessonNo: 8
  },
  {
    id: "4-38",
    diff: 2,
    question: "消費税の課税期間に係る基準期間における課税売上高が 1,000 万円以下の事業者は免税事業者に該当し、「消費税課税事業者選択届出書」を提出する場合を除き、その課税期間において消費税の課税事業者となることはない。",
    lecture: "基準期間における課税売上高が 1,000 万円以下であっても、特定期間における課税売上高と給与等支払額が 1,000 万円超であれば課税事業者になります。",
    answer: 0,
    categoryId: 4,
    num: 38,
    type: 1,
    lessonNo: 9
  },
  {
    id: "4-39",
    diff: 2,
    question: "簡易課税制度を選択することができるのは、消費税の課税期間に係る基準期間における課税売上高が１億円以下の事業者である。",
    lecture: "簡易課税制度を選択できるのは、基準期間における課税売上高が 5,000 万円以下の事業者です。",
    answer: 0,
    categoryId: 4,
    num: 39,
    type: 1,
    lessonNo: 9
  },
  {
    id: "4-40",
    diff: 2,
    question: "消費税の課税事業者である法人は、原則として、消費税の確定申告書を各課税期間の末日の翌日から２カ月以内に、納税地の所轄税務署長に提出しなければならない。",
    lecture: null,
    answer: 1,
    categoryId: 4,
    num: 40,
    type: 1,
    lessonNo: 9
  },
  {
    id: "5-1",
    diff: 3,
    question: "地価公示法による公示価格は、毎年１月１日を標準地の価格判定の基準日としている。",
    lecture: null,
    answer: 1,
    categoryId: 5,
    num: 1,
    type: 1,
    lessonNo: 1
  },
  {
    id: "5-2",
    diff: 3,
    question: "相続税路線価は、地価公示の公示価格の 70％を価格水準の目安として設定されている。",
    lecture: "相続税路線価は、地価公示の公示価格の 80％を目安としています。70%を目安としているのは固定資産税評価額です。",
    answer: 0,
    categoryId: 5,
    num: 2,
    type: 1,
    lessonNo: 1
  },
  {
    id: "5-3",
    diff: 2,
    question: "原価法は、価格時点における対象不動産の再調達原価を求め、この再調達原価について増価修正を行って対象不動産の価格を求める手法である。",
    lecture: "原価法における価格の修正は、老朽化を考慮して減価修正を行うのであって、増加修正ではありません。",
    answer: 0,
    categoryId: 5,
    num: 3,
    type: 1,
    lessonNo: 1
  },
  {
    id: "5-4",
    diff: 2,
    question: "収益還元法のうち直接還元法は、対象不動産の一期間の総収入を還元利回りで還元して対象不動産の価格を求める手法である。",
    lecture: "直接還元法は、総収入ではなく純収益（経費を差し引いた後）の金額を還元利回りで還元（割り戻し）して価格を求めます。",
    answer: 0,
    categoryId: 5,
    num: 4,
    type: 1,
    lessonNo: 1
  },
  {
    id: "5-5",
    diff: 2,
    question: "同一の不動産について二重に売買契約が締結された場合、譲受人相互間においては、売買契約の締結の先後にかかわらず、原則として、所有権移転登記を先にした者が当該不動産の所有権の取得を対抗することができる。",
    lecture: null,
    answer: 1,
    categoryId: 5,
    num: 5,
    type: 1,
    lessonNo: 1
  },
  {
    id: "5-6",
    diff: 3,
    question: "登記事項証明書の交付請求および受領は、インターネットを利用してオンラインで行うことができる。",
    lecture: "登記事項証明書の交付請求はオンラインですることはできますが、受領は窓口か郵送です。",
    answer: 0,
    categoryId: 5,
    num: 6,
    type: 1,
    lessonNo: 1
  },
  {
    id: "5-7",
    diff: 3,
    question: "買主が売主に解約手付を交付した場合、買主が契約の履行に着手するまでは、売主は受領した解約手付を返還して当該契約の解除をすることができる。",
    lecture: "売主は、買主が契約の履行に着手するまでに、受け取った手付金の倍額を現実に提供すると契約を解除することができます。",
    answer: 0,
    categoryId: 5,
    num: 7,
    type: 1,
    lessonNo: 1
  },
  {
    id: "5-8",
    diff: 2,
    question: "民法によれば、売買の目的物である建物が、その売買契約の締結から当該建物の引渡しまでの間に、台風によって全壊した場合、売主の責めに帰すことのできない事由であることから、買主は、売主に対して建物代金の支払いを拒むことはできない。",
    lecture: "契約から引渡しまでに売主の責めに帰すことのできない事由で建物の引き渡しができない場合、買主は建物代金の支払いを拒むことができます。",
    answer: 0,
    categoryId: 5,
    num: 8,
    type: 1,
    lessonNo: 1
  },
  {
    id: "5-9",
    diff: 2,
    question: "売買契約締結後、買主の責めに帰すことのできない事由により、当該契約の目的物の引渡債務の全部が履行不能となった場合、買主は履行の催告をすることなく、直ちに契約の解除をすることができる。",
    lecture: null,
    answer: 1,
    categoryId: 5,
    num: 9,
    type: 1,
    lessonNo: 1
  },
  {
    id: "5-10",
    diff: 3,
    question: "不動産が共有されている場合、各共有者は、自己が有している持分を第三者に譲渡するときには、他の共有者全員の同意を得なければならない。",
    lecture: "自己の共有持分は、他の共有者の同意を得ることなく譲渡（処分）することができます。",
    answer: 0,
    categoryId: 5,
    num: 10,
    type: 1,
    lessonNo: 1
  },
  {
    id: "5-11",
    diff: 2,
    question: "区分建物の登記記録における床面積は、壁その他の区画の内側線で囲まれた部分の水平投影面積（内法面積）により算出される。",
    lecture: null,
    answer: 1,
    categoryId: 5,
    num: 11,
    type: 1,
    lessonNo: 1
  },
  {
    id: "5-12",
    diff: 1,
    question: "宅地建物取引業者が、宅地・建物の貸借の媒介を行う場合に、貸主・借主の双方から受け取ることのできる報酬の合計額の上限は、賃料の２カ月分に相当する額である。",
    lecture: "貸借の媒介の報酬は、貸主・借主の双方から合わせて賃料の１カ月分（＋消費税）が上限です。",
    answer: 0,
    categoryId: 5,
    num: 12,
    type: 1,
    lessonNo: 2
  },
  {
    id: "5-13",
    diff: 2,
    question: "一般定期借地権において、もっぱら事業の用に供する建物の所有を目的とするときは、存続期間を 30 年として設定することができる。",
    lecture: "一般定期借地権の存続期間は、建物の使用目的に関わらず 50 年以上で設定しなければなりません。",
    answer: 0,
    categoryId: 5,
    num: 13,
    type: 1,
    lessonNo: 2
  },
  {
    id: "5-14",
    diff: 2,
    question: "事業用定期借地権等においては、一部を居住の用に供する建物の所有を目的とするときは、その存続期間を 10 年以上 30 年未満として設定することができる。",
    lecture: "事業用定期借地権等においては、一部でも居住の用に供する建物の所有を目的とすることはできません。",
    answer: 0,
    categoryId: 5,
    num: 14,
    type: 1,
    lessonNo: 3
  },
  {
    id: "5-15",
    diff: 2,
    question: "定期借家契約を締結するときは、賃貸人は、あらかじめ、賃借人に対し、契約の更新がなく、期間満了により賃貸借が終了することについて、その旨を 記載した書面を交付し、または、賃借人の承諾を得て当該書面に記載すべき 事項を電磁的方法により提供して、説明しなければならない。",
    lecture: null,
    answer: 1,
    categoryId: 5,
    num: 15,
    type: 1,
    lessonNo: 3
  },
  {
    id: "5-16",
    diff: 2,
    question: "定期借家契約において、経済事情の変動があっても賃料を増減額しないこととする特約をした場合、その特約は有効である。",
    lecture: null,
    answer: 1,
    categoryId: 5,
    num: 16,
    type: 1,
    lessonNo: 3
  },
  {
    id: "5-17",
    diff: 3,
    question: "区分所有者は、敷地利用権が数人で有する所有権である場合、原則として、その有する専有部分とその専有部分に係る敷地利用権とを分離して処分することができない。",
    lecture: null,
    answer: 1,
    categoryId: 5,
    num: 17,
    type: 1,
    lessonNo: 4
  },
  {
    id: "5-18",
    diff: 3,
    question: "区分所有建物の建替えは、集会において、区分所有者および議決権の各４分の３以上の多数により、その旨の決議をすることができる。",
    lecture: "区分所有建物の建替えは、区分所有者および議決権の各５分の４以上の多数により決議することができます。",
    answer: 0,
    categoryId: 5,
    num: 18,
    type: 1,
    lessonNo: 4
  },
  {
    id: "5-19",
    diff: 2,
    question: "市街化区域については用途地域を定め、市街化調整区域については原則として用途地域を定めないものとされている。",
    lecture: null,
    answer: 1,
    categoryId: 5,
    num: 19,
    type: 1,
    lessonNo: 5
  },
  {
    id: "5-20",
    diff: 2,
    question: "農業を営む者の居住の用に供する建築物の建築を目的として市街化調整区域内で行う開発行為は、開発許可を受ける必要がある。",
    lecture: "農業を営む者の居住の用に供する建築物の建築を目的とする開発行為は、市街化調整区域においては許可不要です。",
    answer: 0,
    categoryId: 5,
    num: 20,
    type: 1,
    lessonNo: 5
  },
  {
    id: "5-21",
    diff: 2,
    question: "開発許可を受けた開発区域内の土地においては、開発行為に関する工事完了の公告があるまでの間は、原則として、建築物を建築することができない。",
    lecture: null,
    answer: 1,
    categoryId: 5,
    num: 21,
    type: 1,
    lessonNo: 5
  },
  {
    id: "5-22",
    diff: 1,
    question: "工業の利便を増進するため定める地域である工業専用地域内には、住宅を建てることはできない。",
    lecture: null,
    answer: 1,
    categoryId: 5,
    num: 22,
    type: 1,
    lessonNo: 6
  },
  {
    id: "5-23",
    diff: 2,
    question: "建築基準法第 42 条第２項により道路境界線とみなされる線と道路との間の敷地の部分（セットバック部分）は、建築物を建築することができないが、建蔽率および容積率を算定する際の敷地面積に算入することができる。",
    lecture: "セットバック部分は道路とみなされるため、建蔽率や容積率を算定する際の敷地面積に算入することはできません。",
    answer: 0,
    categoryId: 5,
    num: 23,
    type: 1,
    lessonNo: 6
  },
  {
    id: "5-24",
    diff: 2,
    question: "防火地域内に耐火建築物を建築する場合は、建蔽率および容積率の双方の制限について緩和措置の適用を受けることができる。",
    lecture: "防火地域内に耐火建築物を建築する場合に緩和されるのは、建蔽率だけです。",
    answer: 0,
    categoryId: 5,
    num: 24,
    type: 1,
    lessonNo: 6
  },
  {
    id: "5-25",
    diff: 3,
    question: "建築物の敷地が接する前面道路の幅員が 12 ｍ未満である場合、当該建築物の容積率は、「都市計画で定められた容積率」と「前面道路の幅員に一定の数値を乗じて得たもの」のいずれか高い方の数値以下でなければならない。",
    lecture: "前面道路幅が 12 ｍ未満の際の容積率は、都市計画で定められた容積率（指定容積率）と、一定の計算により求めた容積率の、いずれか低い方の数値以下となります。",
    answer: 0,
    categoryId: 5,
    num: 25,
    type: 1,
    lessonNo: 6
  },
  {
    id: "5-26",
    diff: 2,
    question: "北側斜線制限（北側高さ制限）は、商業地域内の建築物について適用される。",
    lecture: "北側斜線制限が適用されるのは、住居専用地域（一部を除く）や田園住居地域における建築物であり、商業地域内の建築物には適用されません。",
    answer: 0,
    categoryId: 5,
    num: 26,
    type: 1,
    lessonNo: 6
  },
  {
    id: "5-27",
    diff: 3,
    question: "日影規制（日影による中高層の建築物の高さの制限）は、原則として、工業地域および工業専用地域を除く用途地域における建築物に適用される。",
    lecture: "日影規制は、原則として商業地域、工業地域、工業専用地域における建築物には適用されません。",
    answer: 0,
    categoryId: 5,
    num: 27,
    type: 1,
    lessonNo: 6
  },
  {
    id: "5-28",
    diff: 2,
    question: "不動産に抵当権設定登記をする際の登録免許税の課税標準は、当該不動産の相続税評価額である。",
    lecture: "抵当権設定登記の登録免許税の課税標準は、債権金額となります。",
    answer: 0,
    categoryId: 5,
    num: 28,
    type: 1,
    lessonNo: 8
  },
  {
    id: "5-29",
    diff: 2,
    question: "住宅用地に係る固定資産税の課税標準については、住宅１戸当たり 330 ㎡以下の部分について課税標準となるべき価格の６分の１相当額とする特例がある。",
    lecture: "住宅用地に係る固定資産税の課税標準が 6 分の 1 相当額になるのは、住宅 1戸当たり 200㎡以下の部分についてです。",
    answer: 0,
    categoryId: 5,
    num: 29,
    type: 1,
    lessonNo: 8
  },
  {
    id: "5-30",
    diff: 1,
    question: "地方税法において、所定の要件を満たす新築住宅（一般住宅）に係る固定資産税は、１戸あたり 120 ㎡以下の床面積に相当する部分の税額について、一定期間にわたり５分の１に軽減される特例がある。",
    lecture: "新築住宅（一般住宅）の場合は、3 年間または５年間にわたり床面積 120㎡以下の部分の税額が２分の１に軽減されます。",
    answer: 0,
    categoryId: 5,
    num: 30,
    type: 1,
    lessonNo: 8
  },
  {
    id: "5-31",
    diff: 3,
    question: "譲渡するために直接要した仲介手数料は、譲渡所得の金額の計算上、譲渡費用に含まれる。",
    lecture: null,
    answer: 1,
    categoryId: 5,
    num: 31,
    type: 1,
    lessonNo: 8
  },
  {
    id: "5-32",
    diff: 2,
    question: "土地の譲渡所得の金額の計算上、取得費が不明な場合には、譲渡収入金額の15% 相当額を取得費とすることができる。",
    lecture: "譲渡所得の計算において、取得費が不明な場合に使用できる概算取得費は、譲渡収入金額の５%相当額です。",
    answer: 0,
    categoryId: 5,
    num: 32,
    type: 1,
    lessonNo: 8
  },
  {
    id: "5-33",
    diff: 2,
    question: "土地を譲渡した日における所有期間が 5 年以下の場合には、短期譲渡所得に区分され、５年を超える場合には長期譲渡所得に区分される。",
    lecture: "不動産の譲渡所得における所有期間の長期・短期の区分は、譲渡日ではなく、譲渡した年の 1 月 1 日における所有期間が 5 年以下か 5 年超かで判別します。",
    answer: 0,
    categoryId: 5,
    num: 33,
    type: 1,
    lessonNo: 8
  },
  {
    id: "5-34",
    diff: 2,
    question: "相続人が相続により取得した土地を譲渡した場合、その土地の所有期間を判定する際の取得の日は、相続人が当該相続を登記原因として所有権移転登記をした日である。",
    lecture: "譲渡所得の計算において、相続により取得した不動産の取得日の判定は、相続人の取得日ではなく、被相続人が取得した日を引き継ぎます。",
    answer: 0,
    categoryId: 5,
    num: 34,
    type: 1,
    lessonNo: 8
  },
  {
    id: "5-35",
    diff: 3,
    question: "居住用財産を譲渡した場合の 3,000 万円特別控除は、居住用財産を配偶者に譲渡した場合には適用を受けることができない。",
    lecture: null,
    answer: 1,
    categoryId: 5,
    num: 35,
    type: 1,
    lessonNo: 8
  },
  {
    id: "5-36",
    diff: 2,
    question: "居住用財産を譲渡した場合の軽減税率の特例は、譲渡した居住用財産の所有期間を問わず、適用を受けることができる。",
    lecture: "居住用財産を譲渡した場合の軽減税率の特例は、所有期間が、譲渡した年の1 月 1 日において 10 年を超えていなければ、適用を受けることができません。",
    answer: 0,
    categoryId: 5,
    num: 36,
    type: 1,
    lessonNo: 8
  },
  {
    id: "5-37",
    diff: 2,
    question: "居住用財産を譲渡した場合の 3,000 万円の特別控除と軽減税率の特例は、重複して適用を受けることができない。",
    lecture: "居住用財産を譲渡した場合の 3,000 万円の特別控除と軽減税率の特例は、重複適用ができます。",
    answer: 0,
    categoryId: 5,
    num: 37,
    type: 1,
    lessonNo: 8
  },
  {
    id: "5-38",
    diff: 3,
    question: "等価交換方式では、土地所有者は、土地の所有権の一部（持分）を譲渡することにより、その共有地上に建設された建物を全部取得することとなる。",
    lecture: "土地所有者が、等価交換方式で土地の一部（持分）を譲渡した場合、建設された建物の全部ではなく、一部（土地との等価分）を取得することになります。",
    answer: 0,
    categoryId: 5,
    num: 38,
    type: 1,
    lessonNo: 9
  },
  {
    id: "5-39",
    diff: 3,
    question: "ＮＰＶ法（正味現在価値法）による投資判断においては、投資額の現在価値の合計額が対象不動産から得られる収益の現在価値の合計額を上回っている場合、その投資は有利であると判定することができる。",
    lecture: "NPV 法による投資判断においては、対象不動産から得られる収益の現在価値を、投資額の現在価値が下回っている場合に投資に有利（投資適格）であると判定することができます。",
    answer: 0,
    categoryId: 5,
    num: 39,
    type: 1,
    lessonNo: 9
  },
  {
    id: "5-40",
    diff: 2,
    question: "ＮＯＩ利回り（純利回り）は、対象不動産から得られる年間の総収入を総投資額で除して算出される利回りであり、不動産の収益性を測る指標である。",
    lecture: "本問いは表面利回りの説明です。NOI 利回り（純利回り）は、対象不動産から得られる年間の総収入から年間費用（運営コスト）を差し引いた純収益を総投資額で除して算出します。",
    answer: 0,
    categoryId: 5,
    num: 40,
    type: 1,
    lessonNo: 9
  },
  {
    id: "6-1",
    diff: 2,
    question: "親族の範囲は、６親等内の血族、配偶者および３親等内の姻族である。",
    lecture: null,
    answer: 1,
    categoryId: 6,
    num: 1,
    type: 1,
    lessonNo: 1
  },
  {
    id: "6-2",
    diff: 3,
    question: "相続開始時における胎児は、すでに生まれたものとみなされ、死産の場合を除き、相続権が認められる。",
    lecture: null,
    answer: 1,
    categoryId: 6,
    num: 2,
    type: 1,
    lessonNo: 1
  },
  {
    id: "6-3",
    diff: 3,
    question: "養子縁組（特別養子縁組ではない）が成立した場合、養子と実方の父母との親族関係は終了する。",
    lecture: "普通養子縁組では、実父母との親族関係は存続します。",
    answer: 0,
    categoryId: 6,
    num: 3,
    type: 1,
    lessonNo: 1
  },
  {
    id: "6-4",
    diff: 3,
    question: "民法上の相続人について、成年に達した者は、尊属または年長者以外の者を養子とすることができるが、養子には人数制限があり、実子のいる者は 2 人まで、実子のいない者は 3 人までである。",
    lecture: "民法上は、養子の人数制限はありません。",
    answer: 0,
    categoryId: 6,
    num: 4,
    type: 1,
    lessonNo: 1
  },
  {
    id: "6-5",
    diff: 3,
    question: "子や兄弟姉妹が相続の放棄をした場合、放棄をした者の子が、放棄をした者に代わって相続人となる。",
    lecture: "相続放棄した者に代襲相続は生じません。",
    answer: 0,
    categoryId: 6,
    num: 5,
    type: 1,
    lessonNo: 1
  },
  {
    id: "6-6",
    diff: 3,
    question: "相続人が被相続人の配偶者および兄の合計２人である場合、配偶者の法定相続分は３分の２、兄の法定相続分は３分の１である。",
    lecture: "相続人が「配偶者と兄弟姉妹」の場合の法定相続分は、配偶者が４分の３、兄弟姉妹が４分の１です。",
    answer: 0,
    categoryId: 6,
    num: 6,
    type: 1,
    lessonNo: 1
  },
  {
    id: "6-7",
    diff: 2,
    question: "被相続人と父母の一方のみを同じくする兄弟姉妹の法定相続分は、父母の双方を同じくする兄弟姉妹の法定相続分と同じである。",
    lecture: "半血兄弟姉妹と全血兄弟姉妹では法定相続分が違います（半血兄弟姉妹は全血兄弟姉妹の２分の１）。",
    answer: 0,
    categoryId: 6,
    num: 7,
    type: 1,
    lessonNo: 1
  },
  {
    id: "6-8",
    diff: 2,
    question: "相続人が被相続人の長男および孫（相続開始時においてすでに死亡している長女の代襲相続人）の合計２人である場合、長男および孫の法定相続分はそれぞれ２分の１である。",
    lecture: null,
    answer: 1,
    categoryId: 6,
    num: 8,
    type: 1,
    lessonNo: 1
  },
  {
    id: "6-9",
    diff: 2,
    question: "相続の放棄をしようとする者が１人でもいる場合は、相続の開始があったことを知った時から原則として３カ月以内に、共同相続人全員が、家庭裁判所に対して、相続の放棄をする旨を申述しなければならない。",
    lecture: "相続放棄は単独で手続きすることができます。",
    answer: 0,
    categoryId: 6,
    num: 9,
    type: 1,
    lessonNo: 1
  },
  {
    id: "6-10",
    diff: 2,
    question: "公正証書遺言を作成した遺言者は、その遺言を自筆証書遺言によって撤回することができる。",
    lecture: null,
    answer: 1,
    categoryId: 6,
    num: 10,
    type: 1,
    lessonNo: 2
  },
  {
    id: "6-11",
    diff: 2,
    question: "遺産を現物分割する内容の遺産分割協議書を作成する場合、対象となる遺産の一部について遺産分割協議が成立していないときであっても、それを除いた遺産についてのみ定めた遺産分割協議書を作成することができる。",
    lecture: null,
    answer: 1,
    categoryId: 6,
    num: 11,
    type: 1,
    lessonNo: 2
  },
  {
    id: "6-12",
    diff: 1,
    question: "相続税を金銭で納付するために相続により取得した土地を譲渡した場合、その譲渡に係る所得は、所得税の課税対象とならない。",
    lecture: "相続により取得した土地を譲渡した場合には、譲渡所得として相続人の所得税の課税対象となります。",
    answer: 0,
    categoryId: 6,
    num: 12,
    type: 1,
    lessonNo: 2
  },
  {
    id: "6-13",
    diff: 1,
    question: "代償分割により特定の財産（遺産）を取得した相続人から他の相続人に交付された代償財産が不動産や株式であっても、その不動産や株式を交付した相続人には、譲渡所得として所得税が課されることはない。",
    lecture: "代償財産が不動産や株式の場合、時価で他の相続人へ譲渡したものとみなされ、譲渡所得として所得税の対象となります。",
    answer: 0,
    categoryId: 6,
    num: 13,
    type: 1,
    lessonNo: 2
  },
  {
    id: "6-14",
    diff: 2,
    question: "老齢基礎年金の受給権者が死亡し、その者に支給すべき年金給付で、死亡後に支給期の到来する年金を、生計を同じくしていた受給権者の子が受け取った場合、当該年金は相続税の課税対象となる。",
    lecture: "未支給年金は、受け取った者の一時所得となります。",
    answer: 0,
    categoryId: 6,
    num: 14,
    type: 1,
    lessonNo: 4
  },
  {
    id: "6-15",
    diff: 3,
    question: "相続人が受ける死亡退職金の非課税限度額は、被相続人に係る賞与以外の普通給与の３年分相当額である。",
    lecture: "「500 万円×法定相続人の数」が非課税限度額となります。",
    answer: 0,
    categoryId: 6,
    num: 15,
    type: 1,
    lessonNo: 4
  },
  {
    id: "6-16",
    diff: 2,
    question: "相続の放棄をした者が受け取った死亡保険金については、死亡保険金の非課税金額の規定の適用を受けることができない。",
    lecture: null,
    answer: 1,
    categoryId: 6,
    num: 16,
    type: 1,
    lessonNo: 4
  },
  {
    id: "6-17",
    diff: 2,
    question: "本年に被相続人から相続開始前 3 年以内に暦年課税による贈与により取得した上場株式は、その者が相続や遺贈により財産を取得したかどうかにかかわらず、相続税の課税対象となる。",
    lecture: "相続開始前 3 年以内に暦年課税制度により被相続人から贈与を受けた者が、相続や遺贈により財産を取得した場合に、相続税の課税対象となります。なお、2027 年以降の相続から段階的に長くなり（３年超）、2031 年以降に発生する相続からは相続開始前 7 年以内が対象となります。",
    answer: 0,
    categoryId: 6,
    num: 17,
    type: 1,
    lessonNo: 4
  },
  {
    id: "6-18",
    diff: 2,
    question: "2024 年以降、被相続人から相続時精算課税制度による贈与により取得した財産は、その者が相続や遺贈により財産を取得したかどうかにかかわらず、年間 110 万円を超える部分は相続税の課税対象となる。",
    lecture: null,
    answer: 1,
    categoryId: 6,
    num: 18,
    type: 1,
    lessonNo: 4
  },
  {
    id: "6-19",
    diff: 2,
    question: "被相続人が所有していた不動産に係る固定資産税のうち、相続開始時点で納税義務は生じているが、 納付期限が到来していない未払いのものは債務控除の対象とならない。",
    lecture: "相続開始時点で納税義務が生じている未払いの税金は、債務控除することができます。",
    answer: 0,
    categoryId: 6,
    num: 19,
    type: 1,
    lessonNo: 4
  },
  {
    id: "6-20",
    diff: 3,
    question: "すでに死亡している被相続人の子を代襲して相続人となった被相続人の孫は、相続税額の２割加算の対象となる。",
    lecture: "代襲相続人である被相続人の孫は、２割加算の対象とはなりません。",
    answer: 0,
    categoryId: 6,
    num: 20,
    type: 1,
    lessonNo: 4
  },
  {
    id: "6-21",
    diff: 3,
    question: "相続税は金銭による一括納付が原則であるが、一括納付が困難な場合には、納税義務者は、任意に延納または物納を選択することができる。",
    lecture: "延納でも納付が困難な場合は物納が認められます。任意に選択できるわけではありません。",
    answer: 0,
    categoryId: 6,
    num: 21,
    type: 1,
    lessonNo: 5
  },
  {
    id: "6-22",
    diff: 3,
    question: "贈与契約は、当事者の一方がある財産を無償で相手方に与える意思表示をすることにより成立し、相手方が受諾する必要はない。",
    lecture: "贈与契約とは、合意によって成立する契約です。",
    answer: 0,
    categoryId: 6,
    num: 22,
    type: 1,
    lessonNo: 6
  },
  {
    id: "6-23",
    diff: 1,
    question: "書面による贈与契約は、履行前であれば贈与者が一方的に解除することができる。",
    lecture: "書面による贈与契約は、原則、履行前、履行後ともに贈与者が一方的に解除することはできません。",
    answer: 0,
    categoryId: 6,
    num: 23,
    type: 1,
    lessonNo: 6
  },
  {
    id: "6-24",
    diff: 3,
    question: "負担付贈与契約の受贈者が、義務であるその負担を履行しない場合、贈与者が相当の期間を定めてその履行を催告してもその期間内に履行がないときは、贈与者はその贈与契約を解除することができる。",
    lecture: null,
    answer: 1,
    categoryId: 6,
    num: 24,
    type: 1,
    lessonNo: 6
  },
  {
    id: "6-25",
    diff: 3,
    question: "子が父の所有する土地を使用貸借によって借り受けて、その土地の上に賃貸アパートを建築した場合、父から子に土地の使用貸借に係る使用権の価額の贈与があったものとして、贈与税の課税対象となる。",
    lecture: "使用貸借とは、無償もしくは固定資産税相当の費用を支払って土地を借りている場合を指します。この場合は借地権相当額の贈与があったものとはみなされません。",
    answer: 0,
    categoryId: 6,
    num: 25,
    type: 1,
    lessonNo: 6
  },
  {
    id: "6-26",
    diff: 2,
    question: "贈与税の配偶者控除の適用を受ける場合の贈与税額の計算においては、贈与税の課税価格から基礎控除額を控除することができない。",
    lecture: "贈与税の配偶者控除（最高 2,000 万円）と、基礎控除額（110 万円）を合わせて、最高 2,110 万円を控除することができます。",
    answer: 0,
    categoryId: 6,
    num: 26,
    type: 1,
    lessonNo: 7
  },
  {
    id: "6-27",
    diff: 3,
    question: "相続時精算課税制度の適用を受けた贈与財産に係る贈与税額の計算上、認められる特別控除額の限度額は、特定贈与者ごとに累計で 2,000 万円である。",
    lecture: "相続時精算課税制度で認められる特別控除額の限度額は、特定贈与者ごとに累計 2,500 万円です。なお、2024 年以降、特別控除前に年間 110 万円を控除できます。贈与税額＝｛(課税価格 － 年間 110 万円)－ 特別控除 2,500 万円の残額｝× 20％",
    answer: 0,
    categoryId: 6,
    num: 27,
    type: 1,
    lessonNo: 7
  },
  {
    id: "6-28",
    diff: 3,
    question: "相続時精算課税制度の適用を受けた場合、その適用を受けた年以後は、その特定贈与者からの贈与について暦年課税に変更することはできない。",
    lecture: null,
    answer: 1,
    categoryId: 6,
    num: 28,
    type: 1,
    lessonNo: 7
  },
  {
    id: "6-29",
    diff: 3,
    question: "贈与税を納期限までに納付することが困難である場合、その納付を困難とする金額を限度として延納または物納を申請することができる。",
    lecture: "贈与税の納付には物納は認められていません。",
    answer: 0,
    categoryId: 6,
    num: 29,
    type: 1,
    lessonNo: 9
  },
  {
    id: "6-30",
    diff: 2,
    question: "宅地の評価方法として、路線価方式と倍率方式のうち、どちらの方式を採用するかについては、納税者が任意に選択することができる。",
    lecture: "どちらの方式を採用するかは国税庁が定めています。",
    answer: 0,
    categoryId: 6,
    num: 30,
    type: 1,
    lessonNo: 10
  },
  {
    id: "6-31",
    diff: 1,
    question: "宅地の評価方法には、路線価方式と倍率方式があり、それぞれの評価において用いる路線価および倍率は、いずれも路線価図に記載されている。",
    lecture: "倍率方式は路線価図には記載されていません。",
    answer: 0,
    categoryId: 6,
    num: 31,
    type: 1,
    lessonNo: 10
  },
  {
    id: "6-32",
    diff: 3,
    question: "Ａさんの相続開始時にＡさんの子が、Ａさんの所有している土地を使用貸借により借り受けて、その土地の上にアパートを建築して第三者に賃貸していた場合、このアパートの敷地の用に供されている土地は貸宅地として評価する。",
    lecture: "使用貸借に係る宅地の評価は自用地評価額になります。",
    answer: 0,
    categoryId: 6,
    num: 32,
    type: 1,
    lessonNo: 10
  },
  {
    id: "6-33",
    diff: 3,
    question: "Ａさんが、自己が所有する土地の上にアパートを建築し第三者に賃貸していた場合、この土地は貸家建付地として評価する。",
    lecture: null,
    answer: 1,
    categoryId: 6,
    num: 33,
    type: 1,
    lessonNo: 10
  },
  {
    id: "6-34",
    diff: 1,
    question: "貸家建付地の価額は、「自用地としての評価額×（1 −借地権割合）」の算式で計算した金額により評価する。",
    lecture: "貸家建付地の評価の計算式<br>自用地としての評価額×（1 －借地権割合×借家権割合×賃貸割合）",
    answer: 0,
    categoryId: 6,
    num: 34,
    type: 1,
    lessonNo: 10
  },
  {
    id: "6-35",
    diff: 3,
    question: "貸家の価額は、「自用家屋としての評価額×借家権割合×借地権割合×賃貸割合」の算式で計算した金額により評価する。",
    lecture: "貸家の評価の計算式<br>自用家屋としての評価額×（１－借家権割合×賃貸割合）",
    answer: 0,
    categoryId: 6,
    num: 35,
    type: 1,
    lessonNo: 10
  },
  {
    id: "6-36",
    diff: 3,
    question: "特定居住用宅地等の小規模宅地等の評価減の特例において、減額の対象となる限度面積は 330 ㎡、減額割合は 50％である。",
    lecture: "特定居住用宅地等の場合、対象となる限度面積は 330㎡、減額割合は 80％です。",
    answer: 0,
    categoryId: 6,
    num: 36,
    type: 1,
    lessonNo: 10
  },
  {
    id: "6-37",
    diff: 2,
    question: "会社規模が小会社である非上場株式の原則的評価方式は、純資産価額方式であるが、納税義務者の選択により、類似業種比準方式と純資産価額方式の併用方式で評価することもできる。",
    lecture: null,
    answer: 1,
    categoryId: 6,
    num: 37,
    type: 1,
    lessonNo: 11
  },
  {
    id: "6-38",
    diff: 2,
    question: "相続財産の評価において、個人向け国債の価額は、額面金額により評価する。",
    lecture: "個人向け国債は、「額面金額＋既経過利子相当額－中途換金調整額」の式で評価額を求めます。",
    answer: 0,
    categoryId: 6,
    num: 38,
    type: 1,
    lessonNo: 11
  },
  {
    id: "6-39",
    diff: 1,
    question: "純資産価額方式による非上場株式の価額の計算上、自社が課税時期前 3 年以内に取得した土地や建物の価額は、原則として課税時期における通常の取引価額に相当する金額によって評価するため、不動産を取得しても、直ちに純資産価額の引下げ効果が発生するわけではない。",
    lecture: null,
    answer: 1,
    categoryId: 6,
    num: 39,
    type: 1,
    lessonNo: 12
  },
  {
    id: "6-40",
    diff: 1,
    question: "「非上場株式等についての贈与税の納税猶予および免除の特例」の適用を受ける場合、相続時精算課税制度の適用を受けることはできない",
    lecture: "本特例は、相続時精算課税制度と併用して適用を受けることができます。",
    answer: 0,
    categoryId: 6,
    num: 40,
    type: 1,
    lessonNo: 12
  },
  {
    id: "1-j1", // [カテゴリ]-j[連番]
    categoryId: 1, // [カテゴリの番号1~6]
    num: 1, // [連番]
    type: 2, // 必ず2
    lessonNo: 16, // 必ず数字
    lessonNo2: "" // ~(から)や2つ以上ある場合は表示するものを文字列で入力。からがない場合は""
    //answer: 2  多肢択一の場合のみ入力
  },
  {
    id: "1-j2",
    categoryId: 1,
    num: 2,
    type: 2,
    lessonNo: 1,
    lessonNo2: "12-14"
  },
  {
    id: "1-j3",
    categoryId: 1,
    num: 3,
    type: 2,
    lessonNo: 4,
    lessonNo2: ""
  },
  {
    id: "1-j4",
    categoryId: 1,
    num: 4,
    type: 2,
    lessonNo: 1,
    lessonNo2: ""
  },
  {
    id: "1-j5",
    categoryId: 1,
    num: 5,
    type: 2,
    lessonNo: 1,
    lessonNo2: ""
  },
  {
    id: "1-j6",
    categoryId: 1,
    num: 6,
    type: 2,
    lessonNo: 2,
    lessonNo2: ""
  },
  {
    id: "1-j7",
    categoryId: 1,
    num: 7,
    type: 2,
    lessonNo: 2,
    lessonNo2: ""
  },
  {
    id: "1-j8",
    categoryId: 1,
    num: 8,
    type: 2,
    lessonNo: 2,
    lessonNo2: ""
  },
  {
    id: "1-j9",
    categoryId: 1,
    num: 9,
    type: 2,
    lessonNo: 6,
    lessonNo2: "",
    answer: 2
  },
  {
    id: "1-j10",
    categoryId: 1,
    num: 10,
    type: 2,
    lessonNo: 16,
    lessonNo2: "",
    answer: 3
  },
  {
    id: "1-j11",
    categoryId: 1,
    num: 11,
    type: 2,
    lessonNo: 1,
    lessonNo2: ""
  },
  {
    id: "1-j12",
    categoryId: 1,
    num: 12,
    type: 2,
    lessonNo: 8,
    lessonNo2: "",
    answer: 2
  },
  {
    id: "1-j13",
    categoryId: 1,
    num: 13,
    type: 2,
    lessonNo: 8,
    lessonNo2: "",
    answer: 2
  },
  {
    id: "2-j1",
    categoryId: 2,
    num: 1,
    type: 2,
    lessonNo: 4,
    lessonNo2: "4,5"
  },
  {
    id: "2-j2",
    categoryId: 2,
    num: 2,
    type: 2,
    lessonNo: 2,
    lessonNo2: ""
  },
  {
    id: "2-j3",
    categoryId: 2,
    num: 3,
    type: 2,
    lessonNo: 8,
    lessonNo2: ""
  },
  {
    id: "2-j4",
    categoryId: 2,
    num: 4,
    type: 2,
    lessonNo: 7,
    lessonNo2: "",
    answer: 4
  },
  {
    id: "2-j5",
    categoryId: 2,
    num: 5,
    type: 2,
    lessonNo: 5,
    lessonNo2: ""
  },
  {
    id: "3-j1",
    categoryId: 3,
    num: 1,
    type: 2,
    lessonNo: 9,
    lessonNo2: ""
  },
  {
    id: "3-j2",
    categoryId: 3,
    num: 2,
    type: 2,
    lessonNo: 9,
    lessonNo2: ""
  },
  {
    id: "3-j3",
    categoryId: 3,
    num: 3,
    type: 2,
    lessonNo: 11,
    lessonNo2: "",
    answer: 1
  },
  {
    id: "3-j4",
    categoryId: 3,
    num: 4,
    type: 2,
    lessonNo: 13,
    lessonNo2: "",
    answer: 2
  },
  {
    id: "3-j5",
    categoryId: 3,
    num: 5,
    type: 2,
    lessonNo: 9,
    lessonNo2: "",
    answer: 3
  },
  {
    id: "4-j1",
    categoryId: 4,
    num: 1,
    type: 2,
    lessonNo: 9,
    lessonNo2: "2,4"
  },
  {
    id: "4-j2",
    categoryId: 4,
    num: 2,
    type: 2,
    lessonNo: 2,
    lessonNo2: "",
    answer: 1
  },
  {
    id: "4-j3",
    categoryId: 4,
    num: 3,
    type: 2,
    lessonNo: 2,
    lessonNo2: ""
  },
  {
    id: "5-j1",
    categoryId: 5,
    num: 1,
    type: 2,
    lessonNo: 6,
    lessonNo2: ""
  },
  {
    id: "5-j2",
    categoryId: 5,
    num: 2,
    type: 2,
    lessonNo: 1,
    lessonNo2: ""
  },
  {
    id: "5-j3",
    categoryId: 5,
    num: 3,
    type: 2,
    lessonNo: 6,
    lessonNo2: "",
    answer: 3
  },
  {
    id: "6-j1",
    categoryId: 6,
    num: 1,
    type: 2,
    lessonNo: 6,
    lessonNo2: "2,4,9"
  },
  {
    id: "6-j2",
    categoryId: 6,
    num: 2,
    type: 2,
    lessonNo: 6,
    lessonNo2: "1,4"
  },
  {
    id: "6-j3",
    categoryId: 6,
    num: 3,
    type: 2,
    lessonNo: 1,
    lessonNo2: ""
  },
  {
    id: "6-j4",
    categoryId: 6,
    num: 4,
    type: 2,
    lessonNo: 7,
    lessonNo2: ""
  },
  {
    id: "6-j5",
    categoryId: 6,
    num: 5,
    type: 2,
    lessonNo: 9,
    lessonNo2: ""
  },
]