<template>
  <nav
    class="header z-ind"
  >
  <div class="container mx-auto d-flex">
    <router-link
      class="logo-title"
      to="/"
    >{{title}} 問題集</router-link>
    <div style="margin-left: auto;">
      <div class="text-end"> 
        <a class="nav-link font-white p-0" id="UserDropdown" href="#" data-bs-toggle="dropdown" aria-expanded="false"><i class="fas fa-bars"></i></a>
               <div class="dropdown-menu dropdown-menu-right navbar-dropdown" aria-labelledby="UserDropdown">
            <button class="btn btn-link text-dark dropdown-item" @click="reset">学習履歴初期化</button>
                    </div>     
      </div>
    
  </div>
  </div>

  </nav>
</template>

<script lang="ts">
import { Component, Prop, Vue, Emit } from "vue-property-decorator";

import StorageService from "../services/storage";

@Component
export default class Header extends Vue {
  $refs: any = {
    btnDropdownRef: null,
    popoverDropdownRef: null
  };
  private title: string = ""
  private dropdownPopoverShow:boolean = false
  created() {
    switch (process.env.VUE_APP_GRADE)
    {
      case "3":
        this.title = "FP3級"
        break
      case "2":
        this.title = "FP2級"
        break
    }
  }
  mounted() {}
  private reset() {
    if (!window.confirm("本当に学習履歴をクリアしますか？")) return;
    new StorageService().removeData("incorrect")
    location.href = "/"
  }
}
</script>
<style>
nav.header {
  background-color: #01b2a0;
  height: 50px;
  filter: drop-shadow(0px 2px 4px rgba(0,0,0,0.2));
}

.logo-title {
  font-weight: bold;
  color: White;
  line-height: 50px;
  text-decoration: none;
}
.logo-title:hover {
  color: White;
  opacity: 0.7;
}
.z-ind {
  position: relative;
  z-index: 9999 !important;
}
.font-white {
  color: white;
  height: 50px;
  line-height: 50px;
  font-size: 1.3em;
}
</style>